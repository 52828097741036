import React, { useEffect } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";

import styles from "./GooglePlaces.module.css";
import Logo from "../../assets/powered_by_google_on_white.png";
let lonLat_value = "";

function GooglePlaces({ toggle, onAddressSelect, oldAddress }) {
  const [address, setAddress] = React.useState("");
  const [locality, setLocality] = React.useState("");
  const [state, setState] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [postcode, setPostcode] = React.useState("");
  const [lonLat, setLonLat] = React.useState("");
  const [isLoaded, setLoaded] = React.useState(true);

  useEffect(() => {
    if (oldAddress !== null) {
      document.querySelector("#locality").value = oldAddress.locality;
      setLocality(oldAddress.locality);
      document.querySelector("#locality").readOnly = "readonly";

      document.querySelector("#state").value = oldAddress.state;
      setState(oldAddress.state);
      document.querySelector("#state").readOnly = "readonly";

      document.querySelector("#country").value = oldAddress.country;
      setCountry(oldAddress.country);
      document.querySelector("#country").readOnly = "readonly";

      setAddress(oldAddress.address1);

      document.querySelector("#postcode").value = oldAddress.postcode;
      setPostcode(oldAddress.postcode);
      document.querySelector("#postcode").readOnly = "readonly";

      document.getElementById("address2").value = oldAddress.address2;
    }
  }, [oldAddress]);

  const notify = () =>
    toast.error("Please select an address.", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const lngLat = await getLatLng(results[0]);
    setLonLat(lngLat);
    lonLat_value = lngLat;
    const address_com = results[0].address_components;
    let address1 = "";
    let postcode = "";

    address_com.forEach((com) => {
      if (com.types.includes("street_number")) {
        address1 += com.long_name;
      } else if (com.types.includes("route")) {
        address1 = address1 + " " + com.short_name;
      } else if (com.types.includes("postal_code")) {
        postcode += com.long_name;
      } else if (
        com.types.includes("locality") ||
        com.types.includes("sublocality")
      ) {
        document.querySelector("#locality").value = com.long_name;
        setLocality(com.long_name);
        document.querySelector("#locality").readOnly = "readonly";
      } else if (com.types.includes("administrative_area_level_1")) {
        document.querySelector("#state").value = com.short_name;
        setState(com.short_name);
        document.querySelector("#state").readOnly = "readonly";
      } else if (com.types.includes("country")) {
        document.querySelector("#country").value = com.short_name;
        setCountry(com.short_name);
        document.querySelector("#country").readOnly = "readonly";
      }
    });
    if (address1 === "") {
      setAddress(results[0].formatted_address.split(",")[0]);
    } else {
      setAddress(address1);
    }
    setPostcode(postcode);
    document.querySelector("#postcode").value = postcode;
    document.querySelector("#postcode").readOnly = "readonly";
    document.querySelector("#address2").focus();
  };

  const renderSuggestions = (sugg, pro) => {
    const suggestions = sugg.map((suggestion) => {
      const style = {
        backgroundColor: suggestion.active ? "#F5F5FF" : "white",
        fontFamily: "Mulish",
        fontSize: "15",
        padding: "10",
      };
      return (
        <div key={suggestion?.placeId} {...pro(suggestion, { style })}>
          <span>{suggestion.description}</span>
        </div>
      );
    });
    return (
      <>
        {suggestions}
        <li className={styles.logo}>
          <img src={Logo} alt="Powered by Google" />
        </li>
      </>
    );
  };

  const saveAddress = () => {
    if (address !== "" && locality !== "" && state !== "" && country !== "") {
      var ad = {
        address1: address,
        address2: document.getElementById("address2").value,
        locality: locality,
        state: state,
        postcode: postcode,
        country: country,
      };
      if (lonLat === "") {
        onAddressSelect(ad, lonLat_value);
      } else {
        onAddressSelect(ad, lonLat);
      }
      toggle();
    } else {
      notify();
    }
  };

  const clearInputs = (e) => {
    //if(e.key === "Backspace"){
    document.querySelector("#locality").value = "";
    setLocality("");
    document.querySelector("#state").value = "";
    setState("");
    document.querySelector("#country").value = "";
    setCountry("");
    document.querySelector("#postcode").value = "";
    setPostcode("");
    document.querySelector("#address2").value = "";
    //}
  };

  return (
    <div className={styles.main_container}>
      <PlacesAutocomplete
        value={address}
        onChange={setAddress}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <form
              id="address-form"
              action=""
              method="get"
              autoComplete="off"
              className={styles.form_container}
            >
              <div className={styles.header}>
                <button
                  className={styles.exit_btn}
                  onClick={() => {
                    toggle();
                  }}
                >
                  X
                </button>
              </div>
              <label className={styles.full_field}>
                <span className={styles.form_label}>Deliver to*</span>
                <input
                  className={styles.address_input}
                  id="ship-address"
                  name="ship-address"
                  required
                  onKeyDownCapture={(e) => clearInputs(e)}
                  autoComplete="off"
                  {...getInputProps({
                    placeholder: "Search Places ...",
                  })}
                />
                {suggestions.length !== 0 ? (
                  <div className={styles.autocomplete_dropdown_container}>
                    {loading && (
                      <div>
                        <ClipLoader color={"#2B08A0"} size={40} />
                      </div>
                    )}
                    {renderSuggestions(suggestions, getSuggestionItemProps)}
                  </div>
                ) : (
                  <div></div>
                )}
              </label>
              <label className={styles.full_field}>
                <span className={styles.form_label}>
                  Apartment, unit, suite, or floor #
                </span>
                <input
                  className={styles.address_input}
                  id="address2"
                  name="address2"
                  autoComplete="off"
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                />
              </label>
              <label className={styles.full_field}>
                <span className={styles.form_label}>City*</span>
                <input
                  className={styles.address_input}
                  id="locality"
                  name="locality"
                  required
                  autoComplete="off"
                  disabled="disabled"
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                />
              </label>
              <label className={styles.slim_field_left}>
                <span className={styles.form_label}>State/Province*</span>
                <input
                  className={styles.address_input}
                  id="state"
                  name="state"
                  required
                  autoComplete="off"
                  disabled="disabled"
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                />
              </label>
              <label className={styles.slim_field_right} htmlFor="postal_code">
                <span className={styles.form_label}>Postal code*</span>
                <input
                  className={styles.address_input}
                  id="postcode"
                  name="postcode"
                  required
                  autoComplete="off"
                  disabled="disabled"
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                />
              </label>
              <label className={styles.full_field}>
                <span className={styles.form_label}>Country/Region*</span>
                <input
                  className={styles.address_input}
                  id="country"
                  name="country"
                  required
                  autoComplete="off"
                  disabled="disabled"
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                />
              </label>
              <div className={styles.btn_container}>
                {isLoaded === true ? (
                  <button
                    type="button"
                    className={styles.save_btn}
                    onClick={() => {
                      setLoaded(false);
                      saveAddress();
                      setLoaded(true);
                    }}
                  >
                    Save Address
                  </button>
                ) : (
                  <button type="button" className={styles.save_btn}>
                    <ClipLoader color={"#2B08A0"} size={20} />
                  </button>
                )}
              </div>
            </form>
          </div>
        )}
      </PlacesAutocomplete>
      <ToastContainer />
    </div>
  );
}

export default GooglePlaces;
