import { useState } from "react";
import { Alert, DropdownButton, Modal } from "react-bootstrap";
import { handleReportSubmitAPICall } from "../../env/APImanager";
import "./ReportModal.css";
import DropdownItem from "react-bootstrap/esm/DropdownItem";

const ReportModal = (props) => {
  const [text, setText] = useState("");
  const [reasons] = useState(props.reasons);
  const [reasonId, setReasonId] = useState("");
  const [currentReason, setCurrentReason] = useState("");
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSloading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const onSetReasonId = (id) => {
    setReasonId(id);
    setCurrentReason(reasons.find((reason) => reason.id === id).text);
  };

  const handleReportSubmit = async (e) => {
    e.preventDefault();
    setIsError(false);
    setIsLoading(true);
    // If we're successful just close the modal
    if (isSuccessful) {
      props.onHide();
      return;
    }
    try {
      console.debug("[ReportModal] handleReportSubmit: ", props, {
        text,
        reasonId,
        contentType: props.contenttype,
        contentId: props.contentid,
        ownerId: props.ownerid,
      });
      await handleReportSubmitAPICall({
        text,
        reasonId,
        contentType: props.contenttype,
        contentId: props.contentid,
        parentId: props.parentid,
        parentType: props.parenttype,
        ownerId: props.ownerid,
      });
      setIsSuccessful(true);
      props.onSuccessfulReport &&
        props.onSuccessfulReport(props.contenttype, props.contentid);
    } catch (error) {
      setIsError(true);
      setErrorMessage(error.response.data.message || error.message);
      console.warn("[ReportModal] handleReportSubmit error: ", error.response);
    }
    setIsLoading(false);

    // props.onHide();
    // window.location.reload();
  };

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop={false}
      animation={true}
      className="report-modal-gfeed"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <p className="modal-title">
            Report{" "}
            <span style={{ textTransform: "capitalize" }}>
              {props.contenttype}
            </span>
          </p>
        </Modal.Title>
      </Modal.Header>

      <form onSubmit={handleReportSubmit} className="report-modal-form-gfeed">
        <Modal.Body>
          <div className="report-content-container">
            {isError && (
              <div className="report-content-error" style={{ width: "100%" }}>
                <Alert show={isError} variant="warning">
                  {errorMessage}
                </Alert>
              </div>
            )}
            {isSuccessful && (
              <div className="report-content-success" style={{ width: "100%" }}>
                <Alert show={isSuccessful} variant="success">
                  Report submitted successfully!
                </Alert>
              </div>
            )}
            {!isSuccessful && (
              <>
                <div className="report-content-reason">
                  <DropdownButton
                    button
                    block
                    className="report-content-reason-id"
                    title={currentReason || "Please select a reason ..."}
                    size="lg"
                  >
                    {reasons.map((reason, index) => (
                      <DropdownItem
                        key={index}
                        onClick={() => onSetReasonId(reason.id)}
                      >
                        {reason.text}
                      </DropdownItem>
                    ))}
                  </DropdownButton>
                </div>
                <div className="report-content-text-area">
                  <div className="input">
                    <textarea
                      autoresize="true"
                      cols="30"
                      type="text"
                      id="input"
                      value={text}
                      onChange={(e) => setText(e.target.value)}
                      className="input-text"
                      placeholder="What seems to be the matter?"
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </Modal.Body>

        <Modal.Footer className="report-footer-area">
          <div className="report-content-buttons">
            {!isSuccessful && (
              <button
                className="report-content-each-button report-content-button cancel"
                onClick={props.onHide}
              >
                Cancel
              </button>
            )}
            <button
              className="report-content-each-button report-content-button submit"
              disabled={reasonId === ""}
            >
              {isSloading ? "Submitting..." : isSuccessful ? "Done" : "Submit"}
            </button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default ReportModal;
