import { Modal } from "react-bootstrap";
// import { handleFeedPrivacyAPICall } from '../../env/APImanager';

import "./FeedImageModal.css";

const FeedImageModal = (props) => {
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="feed-image-modal-container"
      animation={true}
    >
      <div className="feed-image-modal-buttons-container">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <img src={props.image} alt="Feed" className="feed-full-image" />
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default FeedImageModal;
