import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import AppBar from "../signup/appbar/AppBar";
import { motion } from "framer-motion";
import { ClipLoader } from "react-spinners";
import { getDeliveryOptions, completeDelivery } from "../../env/API";
import styles from "./ChooseDeliveryOption.module.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ChooseDeliveryOption = () => {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  const taskId = params.get("taskId");
  const tokenId = params.get("tokenId");
  const linkingUri = params.get("linkingUri");

  const [screenLoading, setScreenLoading] = useState(false);
  const [deliveryOptions, setDeliveryOptions] = useState(null);
  const [selectedOption, setSelectedOption] = useState({
    id: null,
    title: null,
  });
  const [toastActive, setToastActive] = useState(false);

  const { push } = useHistory();

  useEffect(() => {
    fetchAllData();
  }, []);

  const fetchAllData = async () => {
    setScreenLoading(true);
    await fetchDeliveryOptions();
    setScreenLoading(false);
  };

  const fetchDeliveryOptions = async () => {
    setDeliveryOptions(null);
    const res = await getDeliveryOptions(taskId, tokenId);
    if (res?.data?.data?.options) {
      const unsorted = res.data.data.options;
      const groupByCategory = unsorted.reduce((group, element) => {
        const { category } = element;
        group[category] = group[category] ?? [];
        group[category].push(element);
        return group;
      }, {});
      setDeliveryOptions(groupByCategory);
    }
  };

  const handleNext = async (event) => {
    setScreenLoading(true);
    const option = selectedOption;

    if (option.title && option.id) {
      const res = await completeDelivery(
        taskId,
        tokenId,
        selectedOption.id,
        selectedOption.title
      );
      if (res?.data?.code === "SUCCESS") {
        // window?.ReactNativeWebView?.postMessage(
        // 	JSON.stringify({ code: "TASK_COMPLETED" })
        // );
        window.location.href = `${linkingUri}?code=TASK_COMPLETED`;
      } else {
        if (
          res?.data?.code === "UNAUTHORIZED_USER" ||
          res?.data?.code === "TASK_NOT_FOUND"
        )
          toast.error("the task has been removed from your task list.");
        else {
          toast.error("There was an error marking the task completed.");
        }
        setTimeout(() => {
          if (
            res?.data?.code === "UNAUTHORIZED" ||
            res?.data?.code === "UNAUTHORIZED_USER" ||
            res?.data?.code === "TASK_NOT_FOUND" ||
            res?.data?.code === "MISSING_PARAMETERS"
          ) {
            // window?.ReactNativeWebView?.postMessage( JSON.stringify({ code: "EXIT", message:res?.data?.code}));
            window.location.href = `${linkingUri}?code="EXIT"&message=${res?.data?.code}`;
          }
        }, 5000);
        setToastActive(true);
      }
    } else {
      toast.error("No delivery option is selected.");
      setToastActive(true);
    }
    setScreenLoading(false);
  };

  return (
    <>
      {screenLoading ? (
        <div className={styles["screen-loading"]}>
          <ClipLoader size={100} color="#2B08A0" />
        </div>
      ) : (
        <>
          <AppBar
            key="appbar"
            appName="Choose a Delivery Option"
            goBack={() =>
              push(
                `/grunner/tasks/view/detail/?taskId=${taskId}&tokenId=${tokenId}&linkingUri=${linkingUri}`
              )
            }
          />
          <motion.div className={styles["main-container"]}>
            {deliveryOptions &&
              Object.entries(deliveryOptions).map((array) => (
                <div key={array[0]} className={styles["list-container"]}>
                  <div>
                    <h2>{array[0]}</h2>
                    {array[1].map((option) => (
                      <div key={option.id} className={styles.optionsContainer}>
                        <input
                          type="checkbox"
                          name="option"
                          id={option.id}
                          value={option.id}
                          onChange={(e) =>
                            setSelectedOption({
                              id: option.id,
                              title: option.title,
                            })
                          }
                          checked={
                            option.id === selectedOption.id &&
                            option.title === selectedOption.title
                          }
                        />
                        <label className={styles.label} htmlFor={option.id}>
                          {" "}
                          {option.title}
                        </label>
                        <br />
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            {toastActive ? <ToastContainer /> : <></>}
            <button className={styles["next-button"]} onClick={handleNext}>
              Complete Delivery
            </button>
          </motion.div>
        </>
      )}
    </>
  );
};

export default ChooseDeliveryOption;
