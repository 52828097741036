import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";
import { getTaskByTaskIdAndTokenId, pickedUp } from "../../../env/API";
import Divider from "@mui/material/Divider";
import DeliveryDiningOutlinedIcon from "@mui/icons-material/DeliveryDiningOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import Icon from "@mui/material/Icon";
import styles from "./Task.module.css";
import Avatar from "@mui/material/Avatar";
import AppBar from "./appbar/AppBar";
import ClipLoader from "react-spinners/ClipLoader";
import ProductionQuantityLimitsOutlinedIcon from "@mui/icons-material/ProductionQuantityLimitsOutlined";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PickUpConfirmationModal from "./PickUpConfirmationModal/PickUpConfirmationModal";

const Task = () => {
  const [pickedup, setPickedup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingPickedUp, setLoadingPickedUp] = useState(false);
  const [toastActive, setToastActive] = useState(false);
  const [task, setTask] = useState({});
  const [showConfiramtionModal, setShowConfiramtionModal] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [currentTaskId, setCurrentTaskId] = useState("");
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const tokenId = params.get("tokenId");
  const taskId = params.get("taskId");
  const linkingUri = params.get("linkingUri");
  console.log(taskId);
  const { push } = useHistory();
  const handleConfirmation = () => {
    handleNextPickedUp();
  };
  const getTask = async () => {
    setLoading(true);

    const res = await getTaskByTaskIdAndTokenId(taskId, tokenId);

    if (res?.data?.code === "SUCCESS") {
      setTask(res.data.data.task);

      setLoading(false);
    } else {
      if (
        res?.data?.code === "UNAUTHORIZED_USER" ||
        res?.data?.code === "TASK_NOT_FOUND"
      )
        toast.error("the task has been removed from your task list.");
      else {
        toast.error("There was an error, please try again later.");
      }
      setTimeout(() => {
        if (
          res?.data?.code === "UNAUTHORIZED" ||
          res?.data?.code === "UNAUTHORIZED_USER" ||
          res?.data?.code === "TASK_NOT_FOUND" ||
          res?.data?.code === "MISSING_PARAMETERS"
        ) {
          window?.ReactNativeWebView?.postMessage(
            JSON.stringify({ code: "EXIT", message: res?.data?.code })
          );
          window.location.href = `${linkingUri}?code="EXIT"&message=${res?.data?.code}`;
        }
      }, 5000);
    }
    setLoading(false);

    return res;
  };
  useEffect(() => {
    getTask();
  }, []);

  useEffect(() => {
    if (task?.merchants) isAllPickedUp();
  }, [task]);
  const isAllPickedUp = () => {
    let areAllPickedUp = true;
    task.merchants.forEach((merchant) => {
      if (merchant.taskStatus !== "picked") {
        areAllPickedUp = false;
      }
    });
    setPickedup(areAllPickedUp);
  };

  const handleNextTakePhoto = () => {
    if (pickedup) {
      push(
        `/grunner/tasks/view/photo?taskId=${taskId}&tokenId=${tokenId}&linkingUri=${linkingUri}`
      );
    } else {
      toast.error("Please pick up all items before proceeding");
      setToastActive(true);
    }
    //     setTask((current)=>{
    //     return {...current,dropOffPicture:"sa"}
    // }, console.log("clicked"))
  };
  const handleNextCompleted = () => {
    if (pickedup) {
      push(
        `/grunner/tasks/view/completion_options?taskId=${taskId}&tokenId=${tokenId}&linkingUri=${linkingUri}`
      );
    } else {
      setToastActive(true);
      toast.error("Please pick up all items before proceeding");
    }
  };
  const handleNextContinue = () => {
    if (pickedup) {
      push(
        `/grunner/tasks/view/age-verification?taskId=${taskId}&tokenId=${tokenId}&linkingUri=${linkingUri}`
      );
    } else {
      setToastActive(true);
      toast.error("Please pick up all items before proceeding");
    }
  };
  const handleNextPickedUp = async () => {
    setLoadingPickedUp(true);
    console.log("clicked");

    let confirmedPickup = false;
    const timestamp = new Date().getTime();
    task.merchants.forEach((merchant) => {
      if (merchant.taskId === currentTaskId) {
        confirmedPickup = true;
      }
    });
    if (confirmedPickup) {
      const res = await pickedUp(task, tokenId, currentTaskId, timestamp);
      console.log(res);
      if (res?.data?.code === "SUCCESS") {
        setTask(res.data.data.task);
      } else {
        if (
          res?.data?.code === "UNAUTHORIZED_USER" ||
          res?.data?.code === "TASK_NOT_FOUND"
        )
          toast.error("the task has been removed from your task list.");
        else {
          toast.error("There was an error, please try again later.");
        }
        setTimeout(() => {
          if (
            res?.data?.code === "UNAUTHORIZED" ||
            res?.data?.code === "UNAUTHORIZED_USER" ||
            res?.data?.code === "TASK_NOT_FOUND" ||
            res?.data?.code === "MISSING_PARAMETERS"
          ) {
            window?.ReactNativeWebView?.postMessage(
              JSON.stringify({ code: "EXIT", message: res?.data?.code })
            );
            window.location.href = `${linkingUri}?code="EXIT"&message=${res?.data?.code}`;
          }
        }, 5000);
      }
      let canSetPickup = true;
      task.merchants.forEach((merchant) => {
        if (merchant.pickedUp === false) {
          canSetPickup = false;
        }
      });
      if (canSetPickup) {
        setPickedup(true);
      }
    }

    setLoadingPickedUp(false);
  };
  return (
    <>
      {loading ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ClipLoader size={80} color="#2B08A0" />
        </div>
      ) : (
        <>
          <AppBar
            title={task?.recipientName ? `${task?.recipientName}'s Task` : null}
          ></AppBar>
          <motion.div className={styles["main-container"]}>
            <PickUpConfirmationModal
              show={showConfiramtionModal}
              setShow={setShowConfiramtionModal}
              confirmation={confirmation}
              setConfirmation={setConfirmation}
              taskId={currentTaskId}
              handleConfirmation={handleConfirmation}
            />
            {toastActive ? <ToastContainer /> : <></>}
            <div className={styles["main-container-inner"]}>
              <div className={styles["main-container-div1"]}>
                <div className={styles["main-container-title"]}>
                  <div
                    style={{
                      backgroundColor: "#f0f0ff",
                      borderRadius: "50%",
                      width: "45px",
                      height: "45px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Avatar
                      sx={{ bgcolor: "#d8d8ff", height: "35px", width: "35px" }}
                    >
                      <Icon
                        size=""
                        color="#8585FF"
                        style={{ fill: "#8585FF", fontSize: "25px" }}
                        component={ArticleOutlinedIcon}
                      />
                    </Avatar>
                  </div>
                  <span className={styles["main-container-title-text"]}>
                    Notes
                  </span>
                </div>
                <div className={styles["main-container-payout-div"]}>
                  <span className={styles["main-container-payout-text"]}>
                    You earn ${task?.amountPayedOut?.toFixed(2)}
                  </span>
                </div>
              </div>
            </div>
            <div className={styles["main-container-div2"]}>
              <span className={styles["main-container-delivery-notes"]}>
                {task?.senderDeliveryNotes}
              </span>
            </div>
            <Divider />
            <div className={styles["main-container-inner"]}>
              <div className={styles["main-container-title"]}>
                <div
                  style={{
                    backgroundColor: "#f0f0ff",
                    borderRadius: "50%",
                    width: "45px",
                    height: "45px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Avatar
                    sx={{ bgcolor: "#d8d8ff", height: "35px", width: "35px" }}
                  >
                    <Icon
                      size=""
                      style={{ fill: "#8585FF", fontSize: "25px" }}
                      component={ProductionQuantityLimitsOutlinedIcon}
                    />
                  </Avatar>
                </div>
                <span className={styles["main-container-title-text"]}>
                  Product Details
                </span>
              </div>
              <ol className={styles["main-container-ol"]}>
                {task?.merchants?.map((merchant, index) => {
                  return (
                    <li
                      key={"merchant-" + index}
                      className={styles["main-container-list-item"]}
                    >
                      {/* <div className={styles["task-merchant-div-upper-divider"]}>
                      <Divider />
					  </div> */}
                      <div
                        className={styles["task-merchant-title-div-container"]}
                      >
                        <div className={styles["task-merchant-title-div"]}>
                          <div>
                            <span className={styles["task-merchant-title"]}>
                              {merchant.name}
                            </span>
                            <br />
                            <span className={styles["task-merchant-subtitle"]}>
                              {merchant.location}
                            </span>
                          </div>
                          <div
                            className={
                              styles["task-merchant-div-pickup-button"]
                            }
                          >
                            {loadingPickedUp &&
                            merchant.taskId === currentTaskId ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "120px",
                                }}
                              >
                                <ClipLoader size={40} color="#2B08A0" />
                              </div>
                            ) : merchant.taskStatus === "picked" ? (
                              <span
                                className={
                                  styles["task-merchant-button-picked"]
                                }
                              >
                                Picked
                              </span>
                            ) : (
                              <button
                                disabled={loadingPickedUp}
                                onClick={() => {
                                  setShowConfiramtionModal(true);
                                  setCurrentTaskId(merchant.taskId);
                                }}
                                className={styles["task-merchant-button"]}
                              >
                                Pick Up
                              </button>
                            )}
                          </div>
                        </div>
                        <Divider />
                        <div className={styles["task-merchant-title-div2"]}>
                          <span className={styles["task-merchant-subtitle"]}>
                            Pick up{" "}
                            {merchant?.products?.length > 1
                              ? ` those ${merchant?.products?.length} products`
                              : "this product"}
                          </span>
                        </div>
                        {merchant.products?.map((product, indexproduct) => {
                          return (
                            <div
                              key={
                                "product-" + indexproduct + "merchant-" + index
                              }
                              className={styles["task-product-main-container"]}
                            >
                              <div className={styles["task-product-title-div"]}>
                                <span className={styles["task-product-title"]}>
                                  {product.name}
                                </span>
                              </div>
                              {product.notes ? (
                                <div>
                                  <span
                                    className={styles["task-product-note-text"]}
                                  >
                                    Notes:
                                  </span>
                                  <span
                                    className={styles["task-product-notes"]}
                                  >
                                    {product.notes}
                                  </span>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </li>
                  );
                })}
              </ol>
            </div>
            <Divider />
            <div className={styles["main-container-inner"]}>
              <div className={styles["main-container-title"]}>
                <div
                  style={{
                    backgroundColor: "#f0f0ff",
                    borderRadius: "50%",
                    width: "45px",
                    height: "45px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Avatar
                    sx={{ bgcolor: "#d8d8ff", height: "35px", width: "35px" }}
                  >
                    <Icon
                      size=""
                      style={{ fill: "#8585FF", fontSize: "25px" }}
                      component={DeliveryDiningOutlinedIcon}
                    />
                  </Avatar>
                </div>
                <span className={styles["main-container-title-text"]}>
                  Delivery Details
                </span>
              </div>
              <div className={styles["main-container-delivery-details"]}>
                <div className={styles["main-container-delivery-details-left"]}>
                  <div
                    className={
                      styles["main-container-delivery-details-container"]
                    }
                  >
                    <span>Recipient</span>
                    <br />
                    <span
                      className={styles["main-container-delivery-details-text"]}
                    >
                      {task?.recipientName}
                    </span>
                  </div>
                  <div
                    className={
                      styles["main-container-delivery-details-container"]
                    }
                  >
                    <span>Sender</span>
                    <br />
                    <span
                      className={styles["main-container-delivery-details-text"]}
                    >
                      {task?.senderName}
                    </span>
                  </div>
                  <div
                    className={
                      styles["main-container-delivery-details-container"]
                    }
                  >
                    <span>Deliver By</span>
                    <br />

                    <span
                      className={styles["main-container-delivery-details-text"]}
                    >
                      {task?.deliverBy ? task?.deliverBy : null}
                      {!task?.deliverBy && task?.deliverByMS
                        ? new Date(task?.deliverByMS)?.getMonth() +
                          1 +
                          "/" +
                          new Date(task?.deliverByMS).getDate() +
                          "/" +
                          new Date(task?.deliverByMS)?.getFullYear()
                        : null}
                    </span>
                  </div>
                </div>
                <div
                  className={styles["main-container-delivery-details-right"]}
                >
                  <div
                    className={
                      styles["main-container-delivery-details-container"]
                    }
                  >
                    <span>Address</span>
                    <br />
                    <span
                      className={styles["main-container-delivery-details-text"]}
                    >
                      {task?.address}
                    </span>
                  </div>
                  <div
                    className={
                      styles["main-container-delivery-details-container"]
                    }
                  >
                    <span>Address Extra</span>
                    <br />
                    <span
                      className={styles["main-container-delivery-details-text"]}
                    >
                      {task?.addressExtra}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {task?.verificationNeeded ? (
              task?.verificationConfirmed === true ? (
                task?.dropOffPhoto ? (
                  <button
                    className={styles["next-button"]}
                    onClick={handleNextCompleted}
                  >
                    Complete Delivery
                  </button>
                ) : (
                  <button
                    className={styles["next-button"]}
                    onClick={handleNextTakePhoto}
                  >
                    Take Photo Of Drop Off
                  </button>
                )
              ) : !task?.verificationConfirmed &&
                task?.verificationConfirmed !== false ? (
                <button
                  className={styles["next-button"]}
                  onClick={handleNextContinue}
                >
                  Continue
                </button>
              ) : (
                <button
                  className={styles["next-button"]}
                  onClick={handleNextCompleted}
                >
                  Complete Delivery
                </button>
              )
            ) : task?.dropOffPhoto ? (
              <button
                className={styles["next-button"]}
                onClick={handleNextCompleted}
              >
                Complete Delivery
              </button>
            ) : (
              <button
                className={styles["next-button"]}
                onClick={handleNextTakePhoto}
              >
                Take Photo Of Drop Off
              </button>
            )}
          </motion.div>
        </>
      )}
    </>
  );
};
export default Task;
