import { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import { getPaymentsSetupUrl } from "../../env/API";
import styles from "./PaymentsSetup.module.css";

const PaymentsSetup = () => {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  const tokenId = params.get("tokenId");

  const [screenLoading, setScreenLoading] = useState(false);

  useEffect(() => {
    fetchAllData();
  }, []);

  const fetchAllData = async () => {
    setScreenLoading(true);
    await fetchPaymentsSetupInfo();
    setScreenLoading(false);
  };

  const fetchPaymentsSetupInfo = async () => {
    const res = await getPaymentsSetupUrl(tokenId);
    if (res?.data?.url) {
      let extURL = res.data.url;
      setScreenLoading(false);
      window.location.replace(extURL);
    }
  };

  return (
    <>
      {screenLoading ? (
        <div className={styles["screen-loading"]}>
          <ClipLoader size={100} color="#2B08A0" />
        </div>
      ) : null}
    </>
  );
};

export default PaymentsSetup;
