import React, { useEffect, useState } from "react";
import NavBar from "../NavBar/NavBar";
import defaultGiftImg from "../../assets/defaultGiftImg.png";
import ClipLoader from "react-spinners/ClipLoader";
import close from "../../assets/close.png";
import {
  GETConsumerDataByOrderID,
  notifyReceipiantOfSmartSendOrder,
} from "../../env/APIManager";
import trackStyles from "./Track.module.css";
import { useHistory } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import TrackNotifyReceipentModal from "./TrackNotifyReceipentModal/TrackNotifyReceipentModal";
import "react-toastify/dist/ReactToastify.css";
let search, params, tokenId, view, appbar, orderId;

const Track = () => {
  let history = useHistory();
  search = window.location.search;
  params = new URLSearchParams(search);
  orderId = params.get("orderId");
  tokenId = params.get("tokenId");
  view = params.get("view");
  appbar = params.get("appbar");
  const [sendName, setsendName] = useState("-");
  const [recName, setrecName] = useState("-");
  const [delTime, setdelTime] = useState(null);
  const [statusText, setstatusText] = useState("-");
  const [couponAmount, setCouponAmount] = useState("-");
  const [creditAmount, setCreditAmount] = useState("-");
  const [tip, setTip] = useState("-");
  const [productAmount, setProductAmount] = useState('"-');
  const [productName, setProductName] = useState("-");
  const [serviceFee, setServiceFee] = useState("-");
  const [dropoffName, setDropoffName] = useState("-");
  const [orderTime, setOrderTime] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [text, setText] = useState(false);
  const [orderTotal, setOrderTotal] = useState("");
  // const [delivery_date_text, setdelivery_date_text] = useState(null);
  const [delivery_time_text, setdelivery_time_text] = useState(null);
  const [gHandle, setgHandle] = useState("N/A");
  const [clicked, setclicked] = useState(false);
  const [giftingMethod, setgiftingMethod] = useState("-");
  const [giftingMethodName, setgiftingMethodName] = useState("-");
  const [personalMessage, setPersonalMessage] = useState(null);
  const [giftImg, setGiftImg] = useState();
  const [deliveryNotes, setDeliveryNotes] = useState(
    "No delivery notes were provided."
  );
  const [priceBreakDown, setPriceBreakDown] = useState({});
  const [notifyReceipentModalActive, setNotifyReceipentModalActive] =
    useState(false);
  const [recEmail, setrecEmail] = useState("N/A");
  const [recPhone, setrecPhone] = useState("N/A");
  const [platform, setplatform] = useState("-");
  const [cancelled, setCancelled] = useState(null);
  const [refund, setRefund] = useState(null);
  const [viewlink] = useState(false);
  const [deliveryTime, setdeliveryTime] = useState(null);
  const [pmId, setpmId] = useState(null);
  const [brandDiscount, setBrandDiscount] = useState(null);
  const [brandPercentage, setbrandPercentage] = useState(null);
  const [smartSendNotificationTimes, setSmartSendNotificationTimes] = useState(
    []
  );
  const [nextSmartSendNotification, setNextSmartSendNotification] = useState(0);
  const [attempts_max_reached, setAttempts_max_reached] = useState(false);
  const [notified, setNotified] = useState(false);
  const [toastActive, setToastActive] = useState(false);
  const [campaignData, setCampaignData] = useState({
    isCampaign: false,
    isCampaignFree: false,
    isSenderToSelf: false,
  });
  const handleViewBtn = () => {
    var modal = document.getElementById("myModal");
    modal.style.display = "block";
    setclicked(true);
  };

  const handleCloseBtn = (e) => {
    var modal = document.getElementById("myModal");
    modal.style.display = "none";
    setclicked(false);
  };

  const handleClick = () => {
    history.push(
      `/glink?orderId=${orderId}&tokenId=${tokenId}&appbar=true&view=consumer&redeemed=${deliveryTime}`
    );
  };

  const redirectPM = () => {
    history.push(
      `/personal-message?pmId=${pmId}&orderId=${orderId}&tokenId=${tokenId}&appbar=true&view=${view}`
    );
  };

  useEffect(() => {
    GETConsumerDataByOrderID(orderId)
      .then((res) => {
        if (res.data.isDataReady === true) {
          setSmartSendNotificationTimes(res.data.smartSendNotificationTimes);
          setNextSmartSendNotification(res.data.nextSmartSendNotification);
          setAttempts_max_reached(res.data.attempts_max_reached);
        }
      })

      .catch((err) => console.log(err));
  }, [notified]);
  useEffect(() => {
    GETConsumerDataByOrderID(orderId)
      .then((res) => {
        if (res.data.isDataReady === true) {
          sessionStorage.setItem("notified", false);
          setPriceBreakDown(res.data.priceBreakDown);
          setsendName(res.data.senderName);
          setrecName(res.data.recipientName);
          setdelTime(res.data.deliveryTime);
          setstatusText(res.data.statusText);
          // setdelivery_date_text(res.data.deliveryDateText);
          setdelivery_time_text(res.data.deliveryTimeText);
          setCreditAmount(res?.data?.creditAmount);
          setCouponAmount(res?.data?.couponAmount);
          setDropoffName(res.data.dropoffName);
          setProductName(res.data.productName);
          setOrderTime(res.data.timeOrderPlaced);
          setProductAmount(res?.data?.productAmount);
          setServiceFee(res?.data?.serviceFee);
          setTip(res?.data?.tip);
          setOrderTotal(res?.data?.totalPrice);
          setLoaded(true);
          setText(false);
          setSmartSendNotificationTimes(res.data.smartSendNotificationTimes);
          setNextSmartSendNotification(res.data.nextSmartSendNotification);
          setNextSmartSendNotification(res.data.nextSmartSendNotification);
          setAttempts_max_reached(res.data.attempts_max_reached);
          setpmId(res.data.pmId);
          setgHandle(res?.data?.recipientScreenName);
          setgiftingMethod(res?.data?.giftingMethod); //SmartSend / OldSchool /Gesture Anywhere
          if (res?.data?.giftingMethod === 1) {
            setgiftingMethodName("SmartSend");
          } else if (res?.data?.giftingMethod === 2) {
            setgiftingMethodName("Old School");
          } else if (res?.data?.giftingMethod === 3) {
            setgiftingMethodName("Gesture Anywhere");
          }
          setPersonalMessage(res?.data?.message);
          setDeliveryNotes(res?.data?.senderDeliveryNotes);
          setrecEmail(res?.data?.recipientEmail);
          setrecPhone(res?.data?.recipientPhone);
          setplatform(res?.data?.platformName);
          setCampaignData({
            isCampaign: res?.data?.isCampaign || false,
            isCampaignFree: res?.data?.isCampaignFree || false,
            isSenderToSelf: res?.data?.isSenderToSelf || false,
          });
          if (res?.data?.cancelled) {
            setCancelled(res?.data?.cancelled);
          }
          if (res?.data?.refund) {
            setRefund(res?.data?.refund);
          }
          if (res?.data?.brandDiscount) {
            setBrandDiscount(res?.data?.brandDiscount);
            var temp = parseFloat(res?.data?.brandDiscount);
            temp = ((temp / res?.data?.productAmount) * 100).toFixed(0);
            setbrandPercentage(temp);
          }
          if (res?.data?.productImageUrl) {
            setGiftImg(res?.data?.productImageUrl);
          }
          if (res?.data?.deliveryTime) {
            setdeliveryTime(true);
          } else {
            setdeliveryTime(false);
          }
        } else {
          setText(res.data.statusText);
        }
      })
      .catch((err) => console.log(err));
  }, []);
  const handleNotifyReceipant = async () => {
    setLoaded(false);
    const res = await notifyReceipiantOfSmartSendOrder(orderId, tokenId);
    if (res?.data?.code === "SUCCESS") {
      sessionStorage.setItem("notified", true);
      setNotified(true);
      toast.success(
        "We will notify " +
          (recName ? recName : "your recipient") +
          " within the next 5 minutes."
      );
      setToastActive(true);
    } else {
      toast.error(
        "There was an error please try again later or reach out to support."
      );
      setToastActive(true);
    }
    setLoaded(true);
  };
  const handleNotifyReceipantBTN = async () => {
    setNotifyReceipentModalActive(true);
  };
  useEffect(() => {
    const orderDetails = async () => {
      await GETConsumerDataByOrderID(orderId)
        .then((res) => {
          if (res.data.isDataReady === true) {
            setsendName(res.data.senderName);
            setrecName(res.data.recipientName);
            setdelTime(res.data.deliveryTime);
            setstatusText(res.data.statusText);
            // setdelivery_date_text(res.data.deliveryDateText);
            setdelivery_time_text(res.data.deliveryTimeText);
            setCreditAmount(res?.data?.creditAmount);
            setCouponAmount(res?.data?.couponAmount);
            setDropoffName(res.data.dropoffName);
            setProductName(res.data.productName);
            setOrderTime(res.data.timeOrderPlaced);
            setProductAmount(res?.data?.productAmount);
            setServiceFee(res?.data?.serviceFee);
            setTip(res?.data?.tip);
            setOrderTotal(res?.data?.totalPrice);
            setLoaded(true);
            setText(false);
            setpmId(res.data.pmId);
            setSmartSendNotificationTimes(res.data.smartSendNotificationTimes);
            setNextSmartSendNotification(res.data.nextSmartSendNotification);
            setNextSmartSendNotification(res.data.nextSmartSendNotification);
            setAttempts_max_reached(res.data.attempts_max_reached);
            setgHandle(res?.data?.recipientScreenName);
            setgiftingMethod(res?.data?.giftingMethod); //SmartSend / OldSchool /Gesture Anywhere
            if (res?.data?.giftingMethod === 1) {
              setgiftingMethodName("SmartSend");
            } else if (res?.data?.giftingMethod === 2) {
              setgiftingMethodName("Old School");
            } else if (res?.data?.giftingMethod === 3) {
              setgiftingMethodName("Gesture Anywhere");
            }
            setPersonalMessage(res?.data?.message);
            setDeliveryNotes(res?.data?.senderDeliveryNotes);
            setrecEmail(res?.data?.recipientEmail);
            setrecPhone(res?.data?.recipientPhone);
            setplatform(res?.data?.platformName);
            setCampaignData({
              isCampaign: res?.data?.isCampaign || false,
              isCampaignFree: res?.data?.isCampaignFree || false,
              isSenderToSelf: res?.data?.isSenderToSelf || false,
            });
            if (res?.data?.cancelled) {
              setCancelled(res?.data?.cancelled);
            }
            if (res?.data?.refund) {
              setRefund(res?.data?.refund);
            }
            if (res?.data?.brandDiscount) {
              setBrandDiscount(res?.data?.brandDiscount);
              var temp = parseFloat(res?.data?.brandDiscount);
              temp = ((temp / res?.data?.productAmount) * 100).toFixed(0);
              setbrandPercentage(temp);
            }
            if (res?.data?.productImageUrl) {
              setGiftImg(res?.data?.productImageUrl);
            }
            if (res?.data?.deliveryTime) {
              setdeliveryTime(true);
            } else {
              setdeliveryTime(false);
            }
            clearInterval(interval);
          }
        })
        .catch((err) => console.log(err));
    };
    const interval = setInterval(() => {
      orderDetails();
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  if (viewlink) {
    return (
      <Redirect
        to={`/glink?orderId=${orderId}&tokenId=${tokenId}&appbar=true&view=recipient`}
      />
    );
  }

  if (view === "recipient") {
    return (
      <div className={trackStyles.StyledPage}>
        {appbar === "true" ? <NavBar /> : <div></div>}
        {!loaded ? (
          text === false ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "45vh",
              }}
            >
              <ClipLoader size={100} color="#2B08A0" />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "30vh",
              }}
            >
              <ClipLoader size={100} color="#2B08A0" />
              <div className={trackStyles.statusText}>{text}</div>
            </div>
          )
        ) : (
          <div className={trackStyles.container}>
            <div className={trackStyles.recSpacing}>
              <div className={trackStyles.StyledHeaderText}>
                <b>Sender</b>
              </div>
              <div className={trackStyles.textbox}>
                <div className={trackStyles.regularText}>{sendName}</div>
                {giftingMethod === 3 ? (
                  <>
                    <div className={trackStyles.handle}>
                      <span className={trackStyles.boldText}>
                        Platform:&nbsp;
                      </span>
                      <span className={trackStyles.regularText}>
                        {platform}
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    {/* <div className={trackStyles.handle}><span className={trackStyles.boldText}>G-Handle:&nbsp;</span>
								<span className={trackStyles.regularText}>{gHandle}</span></div> */}
                  </>
                )}
              </div>
            </div>

            <div className={trackStyles.recSpacing}>
              <div className={trackStyles.StyledHeaderText}>
                <b>Recipient Address</b>
              </div>
              <div className={trackStyles.StyledText}>{dropoffName}</div>
            </div>

            <div className={trackStyles.recSpacing}>
              <div className={trackStyles.StyledHeaderText}>
                <b>Delivery Date/Time</b>
              </div>
              <div className={trackStyles.StyledText}>
                {delTime &&
                dropoffName !== undefined &&
                delTime &&
                dropoffName != null
                  ? `${
                      delTime
                        ? Intl?.DateTimeFormat(navigator?.language, {
                            weekday: "long",
                            month: "long",
                            day: "numeric",
                            year: "numeric",
                          }).format(new Date(delTime))
                        : "-"
                    }, ${delivery_time_text}`
                  : "Recipient hasn't inputted their information yet"}
              </div>
            </div>
            <div className={trackStyles.recSpacing}>
              <div className={trackStyles.StyledHeaderText}>
                <b>Gesture Received</b>
              </div>
              <div className={trackStyles.mediumText}>{productName}!</div>
            </div>

            <div className={trackStyles.StyledHeaderText}>
              <b>Personal Message</b>
            </div>
            {pmId ? (
              <div className={trackStyles.bottomSpacing}>
                <button
                  className={trackStyles.viewBtn}
                  onClick={() => redirectPM()}
                >
                  VIEW
                </button>
              </div>
            ) : personalMessage ? (
              clicked === false ? (
                <div className={trackStyles.bottomSpacing}>
                  <button
                    className={trackStyles.viewBtn}
                    onClick={() => handleViewBtn()}
                  >
                    VIEW
                  </button>
                </div>
              ) : (
                <></>
              )
            ) : (
              <div className={trackStyles.textbox}>
                <div className={trackStyles.regularText}>
                  No personal message was provided.
                </div>
              </div>
            )}

            <div id="myModal" className={trackStyles.modal}>
              <div className={trackStyles.modalContent}>
                <img
                  alt="close button"
                  src={close}
                  className={trackStyles.close}
                  onClick={(e) => handleCloseBtn(e)}
                ></img>

                <span className={trackStyles.modalHeader}>
                  Personal Message
                </span>
                <p className={trackStyles.contentText}>{personalMessage}</p>
              </div>
            </div>
            {/* {giftingMethod ===3?
						<><div className={trackStyles.StyledHeaderText}>
							<b>G-Link</b>
							</div>
						<button className={trackStyles.viewBtn} onClick={()=>handleViewLlink()}>VIEW</button></>
						:<></>} */}
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div className={trackStyles.StyledPage}>
        {toastActive ? <ToastContainer /> : <></>}
        {notifyReceipentModalActive ? (
          <TrackNotifyReceipentModal
            handleNotifyReceipant={handleNotifyReceipant}
            notified={notified}
            show={notifyReceipentModalActive}
            setShow={setNotifyReceipentModalActive}
            recName={recName}
          />
        ) : (
          <></>
        )}

        {appbar === "true" ? <NavBar sendName={sendName} /> : <div></div>}
        {!loaded ? (
          text === false ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "45vh",
              }}
            >
              <ClipLoader size={100} color="#2B08A0" />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "30vh",
              }}
            >
              <ClipLoader size={100} color="#2B08A0" />
              <div className={trackStyles.statusText}>{text}</div>
            </div>
          )
        ) : (
          <div className={trackStyles.container}>
            <div className={trackStyles.outer}>
              <div className={trackStyles.detailBox}>
                <span className={trackStyles.detailHeader}>Order Status:</span>

                <span className={trackStyles.detailContent}>{statusText}</span>
              </div>
              <div className={trackStyles.detailBox}>
                <span className={trackStyles.detailHeader}>Order Time:</span>
                <span className={trackStyles.detailContent}>
                  {orderTime
                    ? Intl?.DateTimeFormat(navigator?.language, {
                        weekday: "long",
                        month: "long",
                        day: "numeric",
                        year: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                      }).format(new Date(orderTime))
                    : "-"}
                </span>
              </div>
              {!campaignData?.isCampaign &&
              giftingMethod !== null &&
              giftingMethod !== undefined &&
              giftingMethod !== "-" ? (
                <>
                  <div className={trackStyles.detailBox}>
                    <div className={trackStyles.detailDivHeader}>
                      <span className={trackStyles.detailHeader}>
                        Delivery Method:
                      </span>
                      {giftingMethod === 1 &&
                      deliveryTime !== true &&
                      cancelled !== true ? (
                        <button
                          className={trackStyles.notifyBtn}
                          onClick={handleNotifyReceipantBTN}
                        >
                          {" "}
                          Notify Recipient Now
                        </button>
                      ) : (
                        <></>
                      )}
                    </div>
                    <span className={trackStyles.methodText}>
                      {giftingMethodName}
                    </span>
                    {giftingMethod === 1 ? (
                      <div>
                        {smartSendNotificationTimes &&
                        smartSendNotificationTimes.length !== 0 ? (
                          <>
                            <span className={trackStyles.notifydetail}>
                              {recName ? recName : "Recipient"} was notified on:
                            </span>
                            <br />
                            {smartSendNotificationTimes.map(
                              (notification, index) => (
                                <span
                                  key={"psx" + index}
                                  className={trackStyles.notificationTimes}
                                >
                                  {new Date(notification).toLocaleString(
                                    "en-US"
                                  )}
                                  <br />
                                </span>
                              )
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                        {deliveryTime ? (
                          <></>
                        ) : attempts_max_reached ||
                          nextSmartSendNotification === undefined ||
                          nextSmartSendNotification === null ? (
                          <></>
                        ) : nextSmartSendNotification &&
                          nextSmartSendNotification <= 86400001 ? (
                          <>
                            <span className={trackStyles.notifydetail}>
                              Your recipient will be notified in the next 5
                              minutes
                            </span>
                            <br />
                          </>
                        ) : (
                          <span className={trackStyles.notifydetail}>
                            The next notification will be on:{" "}
                            {new Date(nextSmartSendNotification).toLocaleString(
                              "en-US"
                            )}
                            <br />
                          </span>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
            {giftingMethod === 3 ? (
              <div className={trackStyles.glink}>
                <div className={trackStyles.glinkBox} onClick={handleClick}>
                  Get your G-Link
                </div>
              </div>
            ) : (
              <></>
            )}
            <div className={trackStyles.StyledHeaderText}>
              <b>Recipient</b>
            </div>
            <div className={trackStyles.textbox}>
              {recName !== "" && recName !== null && recName !== undefined ? (
                <div className={trackStyles.regularText}>{recName}</div>
              ) : (
                <div className={trackStyles.regularText}>
                  No recipient name was provided
                </div>
              )}
              {giftingMethod === 3 ? (
                <>
                  <div className={trackStyles.handle}>
                    <span className={trackStyles.boldText}>
                      Platform:&nbsp;
                    </span>
                    <span className={trackStyles.regularText}>{platform}</span>
                  </div>
                </>
              ) : (
                <>
                  {gHandle !== "" && gHandle ? (
                    <>
                      <div className={trackStyles.handle}>
                        <span className={trackStyles.boldText}>
                          G-Handle:&nbsp;
                        </span>
                        <span className={trackStyles.regularText}>
                          {gHandle}
                        </span>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {recEmail !== "" && recEmail ? (
                    <>
                      <div className={trackStyles.handle}>
                        <span className={trackStyles.boldText}>
                          Email:&nbsp;
                        </span>
                        <span className={trackStyles.regularText}>
                          {recEmail}
                        </span>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {recPhone !== "" && recPhone ? (
                    <>
                      <div className={trackStyles.handle}>
                        <span className={trackStyles.boldText}>
                          Phone Number:&nbsp;
                        </span>
                        <span className={trackStyles.regularText}>
                          {recPhone}
                        </span>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
            {giftingMethod === 2 ||
            (dropoffName !== "undefined, undefined" && dropoffName) ? (
              <>
                <div className={trackStyles.StyledHeaderText}>
                  <b>Recipient Address</b>
                </div>
                <div className={trackStyles.StyledText}>{dropoffName}</div>
              </>
            ) : (
              <></>
            )}
            <div className={trackStyles.StyledHeaderText}>
              <b>{campaignData?.isCampaign ? "Product" : "Gesture Sent"}</b>
            </div>
            <div className={trackStyles.giftbox}>
              <img
                alt="gift"
                src={giftImg ? giftImg : defaultGiftImg}
                className={trackStyles.giftImg}
              ></img>
              <div className={trackStyles.giftText}>{productName}</div>
            </div>

            {!campaignData?.isSenderToSelf && (
              <div className={trackStyles.StyledHeaderText}>
                <b>Personal Message</b>
              </div>
            )}

            {!campaignData?.isSenderToSelf && (
              <>
                {pmId ? (
                  <div className={trackStyles.bottomSpacing}>
                    <button
                      className={trackStyles.viewBtn}
                      onClick={() => redirectPM()}
                    >
                      VIEW
                    </button>
                  </div>
                ) : personalMessage ? (
                  clicked === false ? (
                    <div className={trackStyles.bottomSpacing}>
                      <button
                        className={trackStyles.viewBtn}
                        onClick={() => handleViewBtn()}
                      >
                        VIEW
                      </button>
                    </div>
                  ) : (
                    <></>
                  )
                ) : (
                  <div className={trackStyles.textbox}>
                    <div className={trackStyles.regularText}>
                      No personal message was provided.
                    </div>
                  </div>
                )}
                <div id="myModal" className={trackStyles.modal}>
                  <div className={trackStyles.modalContent}>
                    <img
                      alt="close button"
                      src={close}
                      className={trackStyles.close}
                      onClick={(e) => handleCloseBtn(e)}
                    ></img>

                    <span className={trackStyles.modalHeader}>
                      Personal Message
                    </span>
                    <p className={trackStyles.contentText}>{personalMessage}</p>
                  </div>
                </div>
              </>
            )}

            <div className={trackStyles.StyledHeaderText}>
              <b>Delivery Notes</b>
            </div>
            {deliveryNotes ? (
              <>
                <div className={trackStyles.StyledText}>{deliveryNotes}</div>
              </>
            ) : (
              <div className={trackStyles.StyledText}>
                No delivery notes were provided
              </div>
            )}
            {giftingMethod !== 3 ? (
              <>
                {giftingMethod === 2 ? (
                  <>
                    <div className={trackStyles.StyledHeaderText}>
                      <b>Delivery Date/Time</b>
                    </div>
                    <div className={trackStyles.StyledText}>
                      {delTime &&
                      dropoffName !== undefined &&
                      delTime &&
                      dropoffName != null
                        ? `${
                            delTime
                              ? Intl?.DateTimeFormat(navigator?.language, {
                                  weekday: "long",
                                  month: "long",
                                  day: "numeric",
                                  year: "numeric",
                                }).format(new Date(delTime))
                              : "-"
                          }, ${delivery_time_text}`
                        : "Recipient hasn't inputted their information yet"}
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}

            {priceBreakDown && priceBreakDown?.length > 0 ? (
              <>
                <div className={trackStyles.StyledHeaderText}>
                  <b>Payment Summary</b>
                </div>
                <div className={trackStyles.textbox}>
                  {priceBreakDown?.map((el, index) => {
                    const isLast = index === priceBreakDown.length - 1; // Check if the element is the last in the array
                    return (
                      <React.Fragment key={index}>
                        <div className={trackStyles.summaryContent}>
                          <div
                            className={
                              isLast
                                ? trackStyles.summaryTextBold
                                : trackStyles.summaryText
                            }
                          >
                            {el?.title}
                          </div>

                          <div
                            className={
                              isLast
                                ? trackStyles.boldSummaryText
                                : trackStyles.summaryText
                            }
                          >
                            {el?.originalAmount &&
                            !campaignData?.isCampaignFree ? (
                              <>
                                <span
                                  style={{
                                    textDecoration: "line-through",
                                    marginRight: "10px",
                                  }}
                                >
                                  {" "}
                                  ${el?.originalAmount?.toFixed(2)}
                                </span>
                                <span>${el?.amount?.toFixed(2)}</span>
                              </>
                            ) : (
                              `$${el?.amount?.toFixed(2)}`
                            )}
                          </div>
                        </div>
                        {!campaignData?.isCampaignFree && tip
                          ? index === priceBreakDown.length - 2 && (
                              <div className={trackStyles.summaryContent}>
                                <div className={trackStyles.summaryText}>
                                  Tip Amount
                                </div>
                                <div className={trackStyles.summaryText}>
                                  ${tip.toFixed(2)}
                                </div>
                              </div>
                            )
                          : null}

                        {index === priceBreakDown.length - 2 && (
                          <div className={trackStyles.line}></div>
                        )}
                      </React.Fragment>
                    );
                  })}
                </div>
              </>
            ) : (
              <>
                <div className={trackStyles.StyledHeaderText}>
                  <b>Payment Summary</b>
                </div>
                <div className={trackStyles.textbox}>
                  <div className={trackStyles.summaryContent}>
                    <div className={trackStyles.summaryText}>Subtotal</div>
                    <div className={trackStyles.summaryText}>
                      ${productAmount.toFixed(2)}
                    </div>
                  </div>
                  {brandDiscount ? (
                    <div className={trackStyles.summaryContent}>
                      <div
                        className={trackStyles.summaryText}
                        style={{ color: "#EB5D6E" }}
                      >
                        Discount
                      </div>
                      <div
                        className={trackStyles.summaryText}
                        style={{ color: "#EB5D6E" }}
                      >
                        -${brandDiscount?.toFixed(2)} {"("}
                        {brandPercentage}
                        {"% off)"}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className={trackStyles.summaryContent}>
                    <div className={trackStyles.summaryText}>Promo Code</div>
                    <div className={trackStyles.summaryText}>
                      -${couponAmount.toFixed(2)}
                    </div>
                  </div>
                  <div className={trackStyles.summaryContent}>
                    <div className={trackStyles.summaryText}>G-Cash</div>
                    <div className={trackStyles.summaryText}>
                      -${creditAmount.toFixed(2)}
                    </div>
                  </div>
                  <div className={trackStyles.summaryContent}>
                    <div className={trackStyles.summaryText}>Delivery</div>
                    <div className={trackStyles.summaryText}>$9.99</div>
                  </div>
                  <div className={trackStyles.summaryContent}>
                    <div className={trackStyles.summaryText}>Service Fee</div>
                    <div className={trackStyles.summaryText}>
                      ${serviceFee.toFixed(2)}
                    </div>
                  </div>
                  <div className={trackStyles.summaryContent}>
                    <div className={trackStyles.summaryText}>Tip Amount</div>
                    <div className={trackStyles.summaryText}>
                      ${tip.toFixed(2)}
                    </div>
                  </div>
                  {refund ? (
                    <div className={trackStyles.summaryContent}>
                      <div className={trackStyles.summaryText}>Refund</div>
                      <div className={trackStyles.summaryText}>
                        - ${refund?.toFixed(2)}
                      </div>
                    </div>
                  ) : null}
                  <div className={trackStyles.line}></div>

                  <div className={trackStyles.summaryContent}>
                    <div className={trackStyles.summaryTextBold}>TOTAL</div>
                    <div className={trackStyles.summaryTextBold}>
                      ${orderTotal?.toFixed(2)}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    );
  }
};

export default Track;
