import React, { useState } from "react";
import AppBar from "./AppBar";
import { useHistory } from "react-router-dom";

import styles from "./TaskAgeVerification.module.css";
import { toast, ToastContainer } from "react-toastify";
import { updateTaskData } from "../../../env/API";
import ClipLoader from "react-spinners/ClipLoader";

function TaskAgeVerification() {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const tokenId = params.get("tokenId");
  const taskId = params.get("taskId");
  const linkingUri = params.get("linkingUri");

  const [checked, setChecked] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [confirmedTime, setConfimredTime] = useState(null);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const getCurrentTime = async () => {
    const currentDate = new Date();
    const timestamp = currentDate.getTime();
    return timestamp;
  };

  const handleConfirmation = async () => {
    if (checked) {
      setChecked(false);
      setConfirmed(false);
      setConfimredTime(null);
    } else {
      let time = await getCurrentTime();
      setChecked(true);
      setConfirmed(true);
      setConfimredTime(time);
    }
  };

  const handleContinue = async () => {
    setLoading(true);
    if (!confirmed) {
      toast.error(
        "Please confirm that you have read and verified all the requirements"
      );
      setLoading(false);
    } else {
      const res = await updateTaskData(
        taskId,
        tokenId,
        confirmed,
        confirmedTime
      );
      if (res?.data?.code === "SUCCESS") {
        history.push(
          `/grunner/tasks/view/photo?taskId=${taskId}&tokenId=${tokenId}&linkingUri=${linkingUri}`
        );
      } else {
        if (
          res?.data?.code === "UNAUTHORIZED_USER" ||
          res?.data?.code === "TASK_NOT_FOUND"
        )
          toast.error("the task has been removed from your task list.");
        else {
          toast.error("There was an error, please try again later.");
        }
        setTimeout(() => {
          if (
            res?.data?.code === "UNAUTHORIZED" ||
            res?.data?.code === "UNAUTHORIZED_USER" ||
            res?.data?.code === "TASK_NOT_FOUND" ||
            res?.data?.code === "MISSING_PARAMETERS"
          ) {
            window?.ReactNativeWebView?.postMessage(
              JSON.stringify({ code: "EXIT", message: res?.data?.code })
            );
            window.location.href = `${linkingUri}?code="EXIT"&message=${res?.data?.code}`;
          }
        }, 5000);
      }
      setLoading(false);
    }
  };
  const handleNoDelivery = async () => {
    let time = await getCurrentTime();

    setLoading(true);

    if (confirmed) {
      setLoading(false);
      toast.error("Please unselect the checkbox to proceed");
    } else {
      const res = await updateTaskData(taskId, tokenId, confirmed, time);
      if (res?.data?.code === "SUCCESS") {
        history.push(
          `/grunner/tasks/view/completion_options?taskId=${taskId}&tokenId=${tokenId}&linkingUri=${linkingUri}`
        );
      } else {
        if (
          res?.data?.code === "UNAUTHORIZED_USER" ||
          res?.data?.code === "TASK_NOT_FOUND"
        )
          toast.error("the task has been removed from your task list.");
        else {
          toast.error("There was an error, please try again later.");
        }
        setTimeout(() => {
          if (
            res?.data?.code === "UNAUTHORIZED" ||
            res?.data?.code === "UNAUTHORIZED_USER" ||
            res?.data?.code === "TASK_NOT_FOUND" ||
            res?.data?.code === "MISSING_PARAMETERS"
          ) {
            window?.ReactNativeWebView?.postMessage(
              JSON.stringify({ code: "EXIT", message: res?.data?.code })
            );
            window.location.href = `${linkingUri}?code="EXIT"&message=${res?.data?.code}`;
          }
        }, 5000);
      }
      setLoading(false);
    }
  };

  return (
    <>
      <AppBar
        onClickAction={() => {
          history.push(
            `/grunner/tasks/view/detail?taskId=${taskId}&tokenId=${tokenId}&linkingUri=${linkingUri}`
          );
        }}
        title={"Drop Off Instructions"}
      />
      <ToastContainer />
      <div className={styles.mainContainer}>
        <div className={styles.container}>
          <div>
            <div className={styles.mainTitle}>
              {" "}
              Instructions for alcohol delivery
            </div>
            <div className={styles.checkList}>
              {" "}
              <div className={styles.checkListTitle}>Check sobriety</div>
              <ul>
                <li>
                  {" "}
                  It's illegal to complete alcohol delivery to intoxicated
                  customers. Signs of visible intoxication may include: slurred
                  speech, red/watery eyes, lack of physical coordination, crude
                  behavior.
                </li>
              </ul>
            </div>
            <div className={styles.checkList}>
              {" "}
              <div className={styles.checkListTitle}>Request the ID</div>
              <ul>
                <li> Verify that the ID is valid (not expired).</li>
                <li> Verify that the customer is 21+ years old.</li>
              </ul>
            </div>

            <div className={styles.checkList}>
              {" "}
              <div className={styles.checkListTitle}>Check the identity</div>
              <ul>
                <li>
                  {" "}
                  Verify that the person to whom you are delivering the alcohol
                  matches the photo of the person on the ID and that the
                  recipient name matches the name on the ID.
                </li>
                <li> Don't leave alcohol at the door.</li>
              </ul>
            </div>
            <div
              className={styles.checkboxContainer}
              onClick={(e) => handleConfirmation()}
            >
              <input
                type="checkbox"
                checked={checked}
                className={styles.checkbox}
              />
              <div className={styles.checkboxText}>
                I have read, confirmed and verified all the above mentioned
                requirements
              </div>
            </div>
            <div className={styles.noteContainer}>
              <div className={styles.noteText}>
                Important: if you can't confirm and verify all the above
                mentioned requirements, please select "Can't Deliver Order".
                It's illegal to deliver alcohol to somebody who is visible
                intoxicated or under the legal drinking age.
              </div>
            </div>
          </div>

          {loading ? (
            <ClipLoader size={60} color="#2B08A0" />
          ) : (
            <div className={styles.btnsContainer}>
              <div className={styles.btnMainContainer}>
                <button
                  className={styles.btnContainer}
                  onClick={handleContinue}
                >
                  <div className={styles.btnText}> Continue</div>
                </button>
              </div>
              <div className={styles.btnMainContainer}>
                <button
                  className={styles.btnContainer}
                  onClick={handleNoDelivery}
                >
                  <div className={styles.btnText}> Can't Deliver Order</div>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default TaskAgeVerification;
