import React from "react";
import "./NavBar.Styles.css";
import { Link } from "react-router-dom";

import back from "../../assets/back.png";
import styles from "./NavBar.module.css";
import close from "../../assets/close.png";
import help from "../../assets/help.png";
import gLinkStyles from "../GLink/GLink.module.css";

class NavBar extends React.Component {
  WhereTo = (renderview) => {
    if (renderview === "recipient") {
      return "track";
    } else if (renderview === "consumer") {
      return "track";
    }
  };

  handleViewBtn = () => {
    var modal = document.getElementById("myModal");
    modal.style.display = "flex";
  };

  handleCloseBtn = () => {
    var modal = document.getElementById("myModal");
    console.log(modal);
    modal.style.display = "none";
  };

  render() {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className={styles.heading}>
          <div className={styles.content}>
            <div>
              {this.props.backBtn === true ? (
                <div className={styles.contentContainer}>
                  <Link
                    to={`/${this.props.screen}?orderId=${
                      this.props.orderId
                    }&tokenId=${this.props.tokenId}&appbar=${true}&view=${
                      this.props.view
                    }`}
                    style={{ textDecoration: "none", color: "#2B08A0" }}
                  >
                    <img
                      alt="logo"
                      src={back}
                      className={styles.back_btn}
                    ></img>{" "}
                  </Link>
                  <span className={styles.glinkText}>{this.props.header}</span>
                  {this.props.helpBtn === true ? (
                    <img
                      alt="help"
                      src={help}
                      className={styles.help}
                      onClick={() => this.handleViewBtn()}
                    ></img>
                  ) : (
                    <span className={styles.back_btn}></span>
                  )}
                </div>
              ) : (
                <div className={styles.textOnly}>
                  <span className={styles.glinkText}>{this.props.header}</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div id="myModal" className={gLinkStyles.modal}>
          <div className={gLinkStyles.modalContent}>
            <img
              alt="close button"
              src={close}
              className={gLinkStyles.close}
              onClick={() => this.handleCloseBtn()}
            ></img>

            <div className={gLinkStyles.buffer}>
              <div className={gLinkStyles.content}>
                <span className={gLinkStyles.modalHeader}>What is G-Link?</span>
                <p className={gLinkStyles.contentText}>
                  This is your personalized G-Link generated for your order.
                  Send this link to the intended recipient for them to schedule
                  the delivery date and time most convenient for them.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NavBar;
