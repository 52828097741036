import React, { useState, useEffect } from "react";
import { fetchMessageAPICall } from "../../env/APImanager";
import ClipLoader from "react-spinners/ClipLoader";

import AppBar from "../appbar/AppBar";
import Animation from "./Animation";
import PostCard from "./PostCard";
import "./Card.css";

const Card = () => {
  const [message, setMessage] = useState({});
  const [loadingMessage, setLoadingMessage] = useState(false);
  const [fallbackAssets, setFallbackAssets] = useState(false);

  let search = window.location.search;
  let params = new URLSearchParams(search);
  const tokenId = params.get("tokenId");
  const appbar = params.get("appbar") || false;
  const appbarDisplay = appbar === "true";
  const preview = params.get("preview") === "true";

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchMessage();
    document.body.classList.add("message-background");

    return () => {
      document.body.classList.remove("message-background");
    };
  }, []);

  const fetchMessage = async () => {
    setLoadingMessage(true);
    setMessage({});

    let currentMessage = await fetchMessageAPICall(tokenId);

    if (currentMessage == null) {
      window.location.replace("https://gesture.vip");
    } else {
      if (!currentMessage.designElements) {
        setFallbackAssets(true);
      }
      setMessage(currentMessage);
      setLoadingMessage(false);
    }
  };

  if (loadingMessage) {
    return (
      <>
        <section className="message-card-container">
          <div className="message-loading">
            <ClipLoader size={100} color="#2B08A0" />
          </div>
        </section>
      </>
    );
  }

  return (
    <>
      <section className="message-card-container">
        {appbarDisplay && <AppBar />}
        {preview ? (
          <PostCard
            message={message}
            preview={true}
            fallbackAssets={fallbackAssets}
          />
        ) : (
          <Animation message={message} fallbackAssets={fallbackAssets} />
        )}
      </section>
    </>
  );
};

export default Card;
