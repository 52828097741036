import React, { useEffect, useState } from "react";
import styles from "./VendorOrderDetails.module.css";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import ClipLoader from "react-spinners/ClipLoader";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import { getOrderDetailsByOrderId } from "../../../env/APIManager";
import { useHistory } from "react-router-dom";

const CustomExpensesDivider = styled(Divider)`
  background: #000000;
  width: 98%;
  height: 1px;
`;

const VendorOrderDetails = () => {
  const history = useHistory();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  let orderId = params.get("orderId");
  let tokenId = params.get("tokenId");
  //   orderId =
  //     "v-a895b7cf-e1c7-4148-9bf8-655d2ab29b65/024efe91-6b6f-4563-8f15-0ca6342a2eddfe213c47-da93-4c60-84ee-c14b766cb7ab";
  const [orderDetails, setOrderDetails] = useState();
  const [secoundDropoffAddress, setSecoundDropoffAddress] = useState("");
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    var copyText = document.getElementById("email");
    var textArea = document.createElement("textarea");
    var emailContainer = document.getElementById("emailContainer");
    var emailContainer2 = document.getElementById("emailContainer2");
    textArea.value = copyText.textContent;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("Copy");
    textArea.remove();

    emailContainer.style.display = "none";
    emailContainer2.style.display = "block";
    setTimeout(() => {
      emailContainer2.style.display = "none";

      emailContainer.style.display = "block";
    }, 1000);
  };
  const getOrderDetails = async () => {
    setLoading(true);
    const res = await getOrderDetailsByOrderId(orderId + "/" + tokenId);
    if (res?.data?.code === "SUCCESS") {
      setOrderDetails(res?.data?.order);
    }
    setLoading(false);
  };
  useEffect(() => {
    getOrderDetails();
  }, []);
  const [deliveryInformation, setDeliveryInformation] = useState(
    orderDetails?.product_description.toLowerCase() === "n/a" ||
      orderDetails?.product_description === null ||
      orderDetails?.product_description === undefined ||
      orderDetails?.product_description === ""
      ? "You have not provided delivery information "
      : orderDetails?.product_description
  );
  useEffect(() => {
    setDeliveryInformation(
      orderDetails?.product_description.toLowerCase() === "n/a" ||
        orderDetails?.product_description === null ||
        orderDetails?.product_description === undefined ||
        orderDetails?.product_description === ""
        ? "You have not provided delivery information "
        : orderDetails?.product_description
    );
    setSecoundDropoffAddress(
      orderDetails?.apt.toLowerCase() === "n/a" ||
        orderDetails?.apt === null ||
        orderDetails?.apt === undefined ||
        orderDetails?.apt === ""
        ? " Not Provided "
        : orderDetails?.apt
    );
  }, [orderDetails]);
  return (
    <div className={styles["container"]}>
      {loading ? (
        <div className={styles["spinner"]}>
          <ClipLoader size={100} color="#2B08A0" />
        </div>
      ) : (
        <div className={styles["main-container"]}>
          <div className={styles["top-bar"]}>
            <div className={styles["top-bar-bar"]}>
              <KeyboardBackspaceIcon
                style={{ cursor: "pointer" }}
                fontSize="large"
                onClick={() =>
                  history.push("/vendor/order-history?tokenId=" + tokenId)
                }
              />
              <span className={styles["title"]}>
                Order #{orderId.substring(orderId.length - 6)}
              </span>
            </div>
            <div className={styles["top-text-container"]}>
              <span>
                Created on{" "}
                {new Date(orderDetails?.timestamp).toLocaleDateString("en-US")}{" "}
                at{" "}
                {new Date(orderDetails?.timestamp).toLocaleTimeString("en-US")}
              </span>
              <div>Need assistance with this order? </div>
            </div>
            <div className={styles["top-text-container"]}>
              <span>
                <strong>Status: </strong>
                {orderDetails?.status === 0
                  ? "Order is pending"
                  : orderDetails?.status === 1
                  ? `Completed on ${new Date(
                      orderDetails?.schedule
                    ).toLocaleDateString("en-US")} at ${new Date(
                      orderDetails?.schedule
                    ).toLocaleTimeString("en-US")}`
                  : orderDetails?.status === 2
                  ? "Order has been canceled."
                  : ""}
              </span>
              <div>
                <div id="emailContainer">
                  Send email to{" "}
                  <span
                    style={{ color: "blue", textDecoration: "underline" }}
                    // onClick={() =>

                    // 	// (window.location = "mailto:support@gesture.vip")
                    // }
                  >
                    {/* <a
											href={`mailto:support@gesture.vip?subject=VendorOrder#${orderId}`}
											onClick={() => handleClick()}
                      id="email" 
										> */}
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => handleClick()}
                      id="email"
                    >
                      {" "}
                      support@gesture.vip
                    </span>{" "}
                    {/* </a> */}
                  </span>{" "}
                </div>
                <div style={{ display: "none" }} id="emailContainer2">
                  {" "}
                  Copied
                </div>
              </div>
            </div>
          </div>
          <div className={styles["order-details-container"]}>
            <div
              style={{ display: "flex", flexDirection: "column", flex: 0.6 }}
            >
              <div className={styles["order-details-box-left"]}>
                <span className={styles["title-left"]}>Pick Up Location:</span>
                <span> {orderDetails?.pickup}</span>
              </div>
              <div
                style={{ display: "flex", flexDirection: "column", flex: 0.6 }}
              >
                <div className={styles["order-details-box-left"]}>
                  <span className={styles["title-left"]}>Drop Off:</span>
                  <span>
                    {orderDetails?.dropoff}
                    <br />
                    Apt/Floor:{secoundDropoffAddress}
                  </span>
                  <span>
                    Scheduled for delivery on{" "}
                    {orderDetails?.date_readable
                      ? orderDetails?.date_readable
                      : new Date(orderDetails?.schedule).toLocaleDateString(
                          "en-US"
                        ) +
                        " at " +
                        new Date(orderDetails?.schedule).toLocaleTimeString(
                          "en-US"
                        )}
                  </span>
                </div>
              </div>
              <div className={styles["order-details-box-left"]}>
                <span className={styles["title-left"]}>Product:</span>
                <span>{orderDetails?.product_name}</span>
              </div>
              <div className={styles["order-details-box-left"]}>
                <span className={styles["title-left"]}>Customer:</span>
                <span>{orderDetails?.recipient_name}</span>
                <span> {orderDetails?.recipient_phone}</span>
              </div>
              <div className={styles["order-details-box-left"]}>
                <span className={styles["title-left"]}>
                  Delivery Instructions:
                </span>

                <span> {deliveryInformation}</span>
              </div>
            </div>
            <div className={styles["order-details-box-right"]}>
              <span className={styles["title-expenses"]}>Expenses</span>
              <div>
                <CustomExpensesDivider />
              </div>
              <div className={styles["expense-info-div"]}>
                <span>Distance (miles):</span>
                <span>{orderDetails?.distance_miles}</span>
              </div>
              <div className={styles["expense-info-div"]}>
                <span>Total for {orderDetails?.distance_miles} miles:</span>
                <span>${orderDetails?.distance_miles_charge}</span>
              </div>
              <div className={styles["expense-info-div"]}>
                <span>Flat fee:</span>
                <span>${orderDetails?.flat_fee}</span>
              </div>
              <div className={styles["expense-info-div"]}>
                <span>Subtotal:</span>
                <span>
                  $
                  {Number(
                    Number(orderDetails?.distance_miles_charge) +
                      Number(orderDetails?.flat_fee)
                  ).toFixed(2)}
                </span>
              </div>
              <div className={styles["expense-info-div"]}>
                <span>Tip:</span>
                <span>${orderDetails?.tip}</span>
              </div>
              <div>
                <CustomExpensesDivider />
              </div>
              <div className={styles["expense-info-div"]}>
                <span>
                  <strong>Total </strong>
                </span>
                <span>
                  $
                  {Number(
                    Number(orderDetails?.distance_miles_charge) +
                      Number(orderDetails?.flat_fee) +
                      Number(orderDetails?.tip)
                  ).toFixed(2)}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default VendorOrderDetails;
