import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import styles from "./SmartSend.module.css";
import RefreshBar from "../../../OrderHistory/components/layout/RefreshBar.Component";
import back from "../../assets/back.png";
import pen from "../../assets/edit.png";
import mappin from "../../assets/mappin.png";
import Logo from "../../assets/simpleLogo.png";
import SetDateModal from "../layout/setDateModal";
import GoogleSearch from "../layout/AddressSearch";
import {
  GETSmartSendDataByssID,
  GETSmartSendDataByOrderId,
  GETOffsetByLatLon,
  POSTSmartSendByssID,
  POSTDeclinedLocation,
} from "../../env/APIMAnager";
import { NoOrder } from "../../../OrderHistory/components/layout/NoOrderHistory.Components";
import VerifyPhoneModal from "../layout/VerifyPhoneModal";

let search,
  params,
  tokenId,
  refreshbar,
  ssid,
  app,
  orderId,
  trackLink,
  historyLink,
  thankyouPage;

const errorNotify = (text) =>
  toast.error(text, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

function toggleModal(t) {
  t.setState((prevState) => ({ openModal: !prevState.openModal }));
}

function togglePhoneModal(t) {
  t.setState((prevState) => ({ openPhoneModal: !prevState.openPhoneModal }));
}

function ssCompleted(t) {
  thankyouPage = true;
  t.setState({
    isLoaded: true,
  });
}

class SmartSend extends React.Component {
  state = {
    timeseen: false,
    addressseen: false,
    error: null,
    isLoaded: false,
    items: [],
    users: [],
    timenotify: "",
    Address: "Please select an address",
    openModal: false,
    date: "Please Select",
    time: "Please Select",
    senderName: "",
    recipientName: "",
    intendedDate: 0,
    intendedDate_text: "",
    selectedAddress: null,
    updated: false,
    user: false,
    openPhoneModal: false,
    lonLat: null,
    offset: 0,
    loadingOffset: false,
    inApp: false,
    date_text: "",
    delivery_instructions: "",
    productId: "",
    state: "",
    merchantUid: null,
    isProductDeliverable: false,
    cancelled: false,
    hasError: false,
    secureVerifyHeader: null,
  };

  timetogglePop = () => {
    this.setState({
      timeseen: !this.state.timeseen,
    });
  };

  handleBackButton = () => {
    // window.location.replace(historyLink);
    window?.ReactNativeWebView?.postMessage("back");
  };

  addresstogglePop = () => {
    this.setState({
      addressseen: !this.state.addressseen,
    });
  };

  handleDeliveryInstructions = (e) => {
    if (e.target.value.trim()?.length === 0) {
      e.target.value = e.target.value.replace(/\s/g, "");
    } else {
      this.setState({
        delivery_instructions: e?.target?.value?.trim(),
      });
    }
  };

  handleTimeSubmit = async (s) => {
    await this.setState({ timenotify: s });
  };

  showMessage = () => {
    this.setState(
      {
        updated: false,
      },
      () => {
        errorNotify(
          "The new address has been updated. Please set a new date for delivery."
        );
      }
    );
  };
  handleDeclinedLoactionSubmit = async (address) => {
    try {
      const addressData = {
        address: address.address1 || null,
        postcode: address.postcode || null,
        locality: address.locality || null,
        state: address.state || null,
        country: address.country || null,
      };
      const requestBody = {
        data: {
          orderId,
          addressData,
          type: "smartsend",
        },
      };
      await POSTDeclinedLocation(requestBody);
    } catch (error) {
      console.log("error", error);
    }
  };
  handleAddressSubmit = async (s, lonLat) => {
    let string = "";
    if (this.state.selectedAddress) {
      if (
        s.address1 !== this.state.selectedAddress.address1 &&
        this.state.date !== "Please Select" &&
        this.state.time !== "Please Select"
      ) {
        this.setState(
          {
            updated: true,
            date: "Please Select",
            time: "Please Select",
          },
          () => {
            this.showMessage();
          }
        );
      }
    }
    if (s.address2 === "") {
      this.setState({
        selectedAddress: {
          address1: s.address1,
          address2: "",
          locality: s.locality,
          state: s.state,
          postcode: s.postcode,
          country: s.country,
        },
        state: s.state,
        country: s.country,
      });
      string =
        s.address1 +
        ", " +
        s.locality +
        ", " +
        s.state +
        " " +
        s.postcode +
        ", " +
        s.country;
    } else {
      this.setState({
        selectedAddress: {
          address1: s.address1,
          address2: s.address2,
          locality: s.locality,
          state: s.state,
          postcode: s.postcode,
          country: s.country,
        },
        state: s.state,
        country: s.country,
      });
      string =
        s.address1 +
        ", " +
        s.address2 +
        ", " +
        s.locality +
        ", " +
        s.state +
        " " +
        s.postcode +
        ", " +
        s.country;
    }
    this.setState(
      {
        Address: string,
        lonLat: lonLat,
        isLoaded: false,
      },
      () => {
        GETOffsetByLatLon(
          this.state.lonLat.lat,
          this.state.lonLat.lng,
          this.state.productId,
          this.state.state,
          this.state.country,
          orderId
        ).then(
          async (result) => {
            if (result?.code === "SUCCESS") {
              this.setState({
                offset: result?.data?.data?.offset,
                isLoaded: true,
                merchantUid: result?.data?.data?.merchantUid
                  ? result?.data?.data?.merchantUid
                  : null,
                isProductDeliverable:
                  result?.data?.data?.isProductDeliverable !== undefined
                    ? result?.data?.data?.isProductDeliverable
                    : false,
              });
            } else if (result?.code === "COUNTRY_NOT_ALLOWED") {
              await this.handleDeclinedLoactionSubmit(
                this.state.selectedAddress
              );
              this.setState({
                Address: "Please select an address",
                country: null,
                state: null,
                selectedAddress: null,
                isLoaded: true,
                updated: false,
              });
              setTimeout(() => {
                errorNotify(
                  "This product can't be delivered to the selected address. Please choose a different delivery location."
                );
              }, 1000);
            }
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error,
            });
          }
        );
      }
    );
  };

  checkCompletion = () => {
    if (
      this.state.selectedAddress === null &&
      this.state.date === "Please Select" &&
      this.state.time === "Please Select"
    ) {
      errorNotify(
        "Please select a delivery time and an address before confirm."
      );
    } else if (
      this.state.date === "Please Select" &&
      this.state.time === "Please Select"
    ) {
      errorNotify("Please select a delivery time before confirm.");
    } else if (this.state.Address === "Please select an address") {
      errorNotify("Please select an address before confirm.");
    } else if (app === "true") {
      this.handleInAppConfirm();
    } else {
      togglePhoneModal(this);
    }
  };

  handleInAppConfirm = () => {
    var a = this.state.selectedAddress;
    let ssObject = {};
    let time = 0;
    if (this.state.time === "Within 2 hours") {
      time = new Date();
      time.setHours(time.getHours() + 2);
      time = time.getTime();
    } else if (this.state.time === "Before Noon") {
      time = new Date(this.state.date).setHours(12, 0, 0, 0);
    } else if (this.state.time === "10am-2pm") {
      time = new Date(this.state.date).setHours(14, 0, 0, 0);
    } else if (this.state.time === "2pm-8pm") {
      time = new Date(this.state.date).setHours(20, 0, 0, 0);
    }
    ssObject = {
      address_city: a.locality, // The city
      address_extra: a.address2, // The extra APT
      address_state: a.state, // The state
      address_postalCode: a.postcode, // The zip code
      address_country: a.country, // The country
      address_formatted: this.state.Address, // The formatted address
      address_lat: this.state.lonLat.lat, // The latitude
      address_lng: this.state.lonLat.lng, // The longitude
      delivery_time: time,
      // This will be either "now", 10, 14 Basically either "now" or any number (24 hours).
      delivery_date: new Date(this.state.date).setHours(0, 0, 0, 0),
      delivery_time_text: this.state.time,
      delivery_date_text: this.state.date_text,
      recipientDeliveryInstructions: this.state.delivery_instructions,
      isProductDeliverable: this?.state.isProductDeliverable,
      offset: this?.state?.offset,
    };
    if (this?.state?.merchantUid && this?.state?.merchantUid !== null) {
      ssObject.merchantUid = this?.state?.merchantUid;
    }

    this.setState(
      {
        isLoaded: false,
      },
      () => {
        POSTSmartSendByssID(
          ssid,
          ssObject,
          this?.state?.secureVerifyHeader
        ).then(
          (response) => {
            const { result, secureHeader } = response;
            if (result?.code === "SUCCESS") {
              this.setState(
                {
                  isLoaded: true,
                },
                () => {
                  // window.location.replace(trackLink);
                  window?.ReactNativeWebView?.postMessage(`orderId=${orderId}`);
                }
              );
            }
            if (secureHeader) {
              this.setState({ secureVerifyHeader: secureHeader });
            } else {
              this.setState({
                isLoaded: true,
              });
              errorNotify(result?.message);
              setTimeout(() => {
                window.location.reload();
              }, 4000);
            }
          },
          (error) => {
            this.setState(
              {
                isLoaded: true,
                error,
              },
              () => {
                errorNotify("Form submission failed. Please try again.");
              }
            );
          }
        );
      }
    );
  };

  handleConfirmButton = (code, phone, email, recName) => {
    var a = this.state.selectedAddress;
    let ssObject = {};
    let time = 0;
    if (this.state.time === "Within 2 hours") {
      time = new Date();
      time.setHours(time.getHours() + 2);
      time = time.getTime();
    } else if (this.state.time === "Before Noon") {
      time = new Date(this.state.date).setHours(12, 0, 0, 0);
    } else if (this.state.time === "10am-2pm") {
      time = new Date(this.state.date).setHours(14, 0, 0, 0);
    } else if (this.state.time === "2pm-8pm") {
      time = new Date(this.state.date).setHours(20, 0, 0, 0);
    }
    ssObject = {
      address_city: a.locality, // The city
      address_extra: a.address2, // The extra APT
      address_state: a.state, // The state
      address_postalCode: a.postcode, // The zip code
      address_country: a.country, // The country
      address_formatted: this.state.Address, // The formatted address
      address_lat: this.state.lonLat.lat, // The latitude
      address_lng: this.state.lonLat.lng, // The longitude
      delivery_time: time,
      // This will be either "now", 10, 14 Basically either "now" or any number (24 hours).
      delivery_date: new Date(this.state.date).getTime(),
      verificationCode: code,
      phoneNumber: phone,
      email: email,
      delivery_time_text: this.state.time,
      delivery_date_text: this.state.date_text,
      recipientDeliveryInstructions: this.state.delivery_instructions,
      recipient_name: recName,

      isProductDeliverable: this?.state.isProductDeliverable,
      offset: this?.state?.offset,
    };
    if (this?.state?.merchantUid && this?.state?.merchantUid !== null) {
      ssObject.merchantUid = this?.state?.merchantUid;
    }

    return [ssid, ssObject, this?.state?.secureVerifyHeader];
  };

  selectDate = async (date) => {
    await this.setState({
      date: date,
    });
  };

  selectDateText = async (text) => {
    await this.setState({
      date_text: text,
    });
  };

  selectTime = async (time) => {
    await this.setState({
      time: time,
    });
  };

  componentDidMount() {
    search = window.location.search;
    params = new URLSearchParams(search);
    ssid = params.get("ssid");
    app = params.get("app");
    tokenId = params.get("tokenId");
    orderId = params.get("orderId");
    trackLink = `/track?`;
    historyLink = `/history?`;
    var appbar = app === "true" ? true : false;
    trackLink =
      trackLink +
      `orderId=${orderId}&view=recipient&appbar=${appbar}&tokenId=${tokenId}`;
    historyLink =
      historyLink + `tokenId=${tokenId}&refreshbar=true&view=recipient`;
    if (appbar === null) {
      appbar = "false";
    }
    if (app === "true" || appbar === "true") {
      this.setState({
        inApp: true,
      });
    }
    if (ssid != null) {
      // out of app

      GETSmartSendDataByssID(ssid).then(
        (result) => {
          if (result?.data?.submitted_by_recipient === "false") {
            this.setState({
              isLoaded: true,
              senderName: result.data.senderName,
              recipientName: result.data.recipientName,
              intendedDate: result.data.intendedDate,
              intendedDate_text: result.data.intendedDate_text,
              productId: result?.data?.productId,
            });
          } else {
            //if smartSend is completed, show thank you box
            if (app === "false") {
              thankyouPage = true;
              this.setState({
                isLoaded: true,
              });
            } else {
              // window.location.replace(trackLink);
              window?.ReactNativeWebView?.postMessage(`orderId=${orderId}`);
            }
          }
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
    } else if (orderId != null) {
      //in-app

      GETSmartSendDataByOrderId(orderId) //orderID
        .then(
          (response) => {
            const { result, secureVerifyHeader } = response;
            if (result?.code === "ERROR") {
              this.setState({
                isLoaded: true,
                hasError: true,
              });
              errorNotify(result?.message);
              return;
            }
            var s = result?.data?.senderName;
            var r = result?.data?.recipientName;
            var d = parseInt(result?.data?.intendedDate);
            ssid = result?.data?.smartSendId;
            var productId = result?.data?.productId;
            var cancelled = result?.data?.cancelled;

            if (result?.data?.submitted_by_recipient === true) {
              // if smartSend is completed, an emty object will be returned, then reroute to track page
              if (app === "false") {
                thankyouPage = true;
                this.setState({
                  isLoaded: true,
                });
              } else {
                // window.location.replace(trackLink);
                window?.ReactNativeWebView?.postMessage(`orderId=${orderId}`);
              }
            } else {
              this.setState({
                senderName: s,
                recipientName: r,
                intendedDate: d,
                intendedDate_text: result?.data?.intendedDate_text,
                isLoaded: true,
                productId: productId,
                cancelled: cancelled,
                secureVerifyHeader,
              });
            }
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error,
            });
          }
        );
    }
  }

  render() {
    const {
      error,
      intendedDate_text,
      offset,
      openPhoneModal,
      isLoaded,
      openModal,
      time,
      date,
      recipientName,
      senderName,
      intendedDate,
      Address,
      cancelled,
      hasError,
    } = this.state;

    if (error) {
      return (
        <div>
          {refreshbar === "true" ? <RefreshBar bg={"ffffff"} /> : <></>}
          <div className={styles.noOrder}></div>
          <NoOrder></NoOrder>
        </div>
      );
    } else if (!isLoaded) {
      return (
        <div className={styles.loader}>
          <ClipLoader color={"#2B08A0"} size={100} />
        </div>
      );
    } else if (hasError) {
      return (
        <div>
          {refreshbar === "true" ? <RefreshBar bg={"ffffff"} /> : <></>}
          <div className={styles.noOrder}></div>
          <NoOrder></NoOrder>
          <ToastContainer />
        </div>
      );
    } else if (this.state.addressseen) {
      return (
        <span className={styles.popupstime}>
          {/* <AddressEdit toggle={this.addresstogglePop} onAddressSelect={this.handleAddressSubmit}/>  */}
          <GoogleSearch
            toggle={this.addresstogglePop}
            onAddressSelect={this.handleAddressSubmit}
            oldAddress={this.state.selectedAddress}
            handleDeclinedLoactionSubmit={this.handleDeclinedLoactionSubmit}
          />
        </span>
      );
    } else if (thankyouPage === true) {
      return (
        <div className={styles.tybackground}>
          <div className={styles.thankyoubox}>
            <img alt="logo" className={styles.logo_image} src={Logo} />
            <>
              <span className={styles.thankyou_text}>Thank you!</span>
              <span className={styles.thankyou_text2}>
                We have received your delivery details.
              </span>
            </>
            <button
              className={styles.download}
              type="button"
              onClick={(e) => {
                e.preventDefault();
                window.location.href = "https://gestureapp.page.link/smartsend";
              }}
            >
              {" "}
              Download Gesture
            </button>
          </div>
        </div>
      );
    } else if (cancelled) {
      return (
        <div className={styles.tybackground}>
          <div className={styles.thankyoubox}>
            {this.state.inApp === false && (
              <img alt="Log" className={styles.logo_image} src={Logo} />
            )}
            <>
              <span className={styles.thankyou_text}>
                Hi {recipientName?.trim()?.length !== 0 && recipientName}
              </span>
              <span className={styles.thankyou_text2}>
                Looks like{" "}
                {senderName?.split(" ")[0]?.charAt(0)?.toLocaleUpperCase() +
                  senderName?.slice(1)?.split(" ")[0]}{" "}
                sent you a gift. There was a change in this order, keep an eye
                out for future notifications.
              </span>
            </>
            {this.state.inApp === false && (
              <button
                className={styles.download}
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = "https://gesture.vip/";
                }}
              >
                {" "}
                Download Gesture
              </button>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div className={styles.mainBack}>
          {this.state.inApp === true ? (
            <div className={styles.heading}>
              <div className={styles.content}>
                <div className={styles.contentContainer}>
                  <div onClick={() => this.handleBackButton()}>
                    <img
                      alt="back arrow"
                      src={back}
                      className={styles.back_btn}
                    ></img>
                  </div>
                  <span className={styles.headerText}>SmartSend</span>
                  <span className={styles.back_btn}></span>
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.heading2}></div>
          )}
          <div className={styles.ssbackground}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                flex: 1,
              }}
            >
              {this.state.inApp === false ? (
                <img alt="Logo" className={styles.logo_image} src={Logo} />
              ) : (
                <></>
              )}
              {intendedDate && intendedDate_text ? (
                <div className={styles.greeting_text}>
                  {this.state.inApp === false ? (
                    "Hi,"
                  ) : (
                    <>
                      Hi
                      <span className={styles.greeting_text_color2}>
                        {" "}
                        {recipientName
                          .split(" ")[0]
                          .charAt(0)
                          .toLocaleUpperCase() +
                          recipientName.slice(1).split(" ")[0]}
                      </span>
                      ,
                    </>
                  )}
                  <span className={styles.greeting_text_color2}>
                    {" "}
                    {senderName.split(" ")[0].charAt(0).toLocaleUpperCase() +
                      senderName.slice(1).split(" ")[0]}
                  </span>{" "}
                  just sent you a gift intended to be delivered on{" "}
                  {this.state.intendedDate_text}
                </div>
              ) : (
                <div className={styles.greeting_text}>
                  {/* <span className={styles.greeting_text_color2}>
										{recipientName.split(" ")[0].charAt(0).toLocaleUpperCase() +
											recipientName.slice(1).split(" ")[0]}
									</span> */}
                  {this.state.inApp === false ? (
                    "Hi,"
                  ) : recipientName?.trim()?.length === 0 ? (
                    "Hi,"
                  ) : (
                    <>
                      Hi
                      <span className={styles.greeting_text_color2}>
                        {" "}
                        {recipientName
                          .split(" ")[0]
                          .charAt(0)
                          .toLocaleUpperCase() +
                          recipientName.slice(1).split(" ")[0]}
                      </span>
                      ,
                    </>
                  )}
                  <span className={styles.greeting_text_color2}>
                    {" "}
                    {senderName.split(" ")[0].charAt(0).toLocaleUpperCase() +
                      senderName.slice(1).split(" ")[0]}
                  </span>{" "}
                  just sent you a gift
                </div>
              )}
              <div className={styles.address_wrap}>
                <div className={styles.text2}>
                  Tell us where to deliver your gift.
                </div>
                <div className={styles.address_title_text}>Address</div>
                <div className={styles.address} onClick={this.addresstogglePop}>
                  <span>
                    <img
                      alt="mapping"
                      className={styles.mappin}
                      src={mappin}
                    ></img>
                  </span>
                  <span className={styles.address_text}>
                    {" "}
                    {this.state.Address}
                  </span>
                  <span>
                    <img alt="pen" className={styles.edit} src={pen}></img>
                  </span>
                </div>
              </div>

              {this.state.selectedAddress ? (
                <div className={styles.delivery_info_wrap}>
                  <div className={styles.schedule_wrap}>
                    <div className={styles.schedule_text}>
                      Scheduled Delivery
                    </div>
                    {Address !== "Please select an address" ? (
                      <button
                        className={styles.schedule_btn}
                        onClick={(e) => toggleModal(this, e)}
                      >
                        Set Date
                      </button>
                    ) : (
                      <button
                        className={styles.schedule_btn}
                        onClick={() =>
                          errorNotify(
                            "Please select an address before setting a date."
                          )
                        }
                      >
                        Set Date
                      </button>
                    )}
                  </div>
                  <div className={styles.schedule_wrap}>
                    <div className={styles.info_title_text}>Date</div>
                    {Address !== "Please select an address" ? (
                      <span
                        className={styles.info_text}
                        onClick={(e) => toggleModal(this, e)}
                      >
                        {date}
                      </span>
                    ) : (
                      <span
                        className={styles.info_text}
                        onClick={() =>
                          errorNotify(
                            "Please select an address before setting a date."
                          )
                        }
                      >
                        {date}
                      </span>
                    )}
                  </div>
                  <div className={styles.schedule_wrap}>
                    <div className={styles.info_title_text}>Time</div>
                    {Address !== "Please select an address" ? (
                      <span
                        className={styles.info_text}
                        onClick={(e) => toggleModal(this, e)}
                      >
                        {time}
                      </span>
                    ) : (
                      <span
                        className={styles.info_text}
                        onClick={() =>
                          errorNotify(
                            "Please select an address before setting a date."
                          )
                        }
                      >
                        {time}
                      </span>
                    )}
                  </div>
                  <div className={styles.delivery_wrap}>
                    <div className={styles.schedule_text}>
                      Delivery Instructions
                    </div>
                    <textarea
                      col="5"
                      value={this.state.value?.trim()}
                      onChange={this.handleDeliveryInstructions}
                      className={styles.delivery_notes}
                      maxLength={300}
                      placeholder="Type here..."
                    />
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div className={styles.note_text}>
                We'll need to verify your age (21+) if your gift is an alcoholic
                gift. A photo ID must be provided at delivery.
              </div>
            </div>
            <button
              className={styles.confirmed}
              onClick={(e) => {
                this.checkCompletion();
              }}
            >
              Confirm
            </button>
            {/* {this.state.inApp === true ?
        <button className={styles.remind} onClick={()=>this.handleBackButton()}>Remind me later</button> : <></>
        } */}

            <SetDateModal
              show={openModal}
              onClose={(e) => toggleModal(this, e)}
              setDate={this.selectDate}
              setDateText={this.selectDateText}
              setTime={this.selectTime}
              offset={offset}
            >
              Please select a date and time for delivery
            </SetDateModal>
            <VerifyPhoneModal
              show={openPhoneModal}
              onClose={(e) => togglePhoneModal(this, e)}
              submit={this.handleConfirmButton}
              complete={() => ssCompleted(this)}
            >
              Verify your phone
            </VerifyPhoneModal>
            <ToastContainer />
          </div>
        </div>
      );
    }
  }
}

export default SmartSend;
