import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import Persona from "persona";
import AppBar from "../signup/appbar/AppBar";
import { getIdentityVerificationUrl } from "../../env/API";
import styles from "./IdentityVerification.module.css";

const IdentityVerification = () => {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  const tokenId = params.get("tokenId");
  const inapp = params.get("inapp");
  // const appbar  = params.get('appbar') || true;

  const [screenLoading, setScreenLoading] = useState(false);

  const [identityInformation, setIdentityInformation] = useState({});

  let history = useHistory();

  useEffect(() => {
    fetchAllData();
    console.log("call API");
  }, []);

  const fetchAllData = async () => {
    setScreenLoading(true);
    await fetchIdentityVerificationInfo();
    setScreenLoading(false);
  };

  const fetchIdentityVerificationInfo = async () => {
    setIdentityInformation({});
    const res = await getIdentityVerificationUrl(tokenId);
    if (res?.data?.identityInformation) {
      setIdentityInformation({
        templateId: res.data.identityInformation?.templateId,
        environment: res.data.identityInformation?.environment,
        referenceId: res.data.identityInformation?.referenceId,
      });
    }
  };

  return (
    <>
      {screenLoading ? (
        <div className={styles["screen-loading"]}>
          <ClipLoader size={100} color="#2B08A0" />
        </div>
      ) : (
        <>
          {/* {appbar  === "false" ? (
            <></>
          ) : ( */}
          <AppBar
            appName="Identity Verification"
            goBack={() =>
              history.push(
                `/grunner/signup/address_selection/?tokenId=${tokenId}&inapp=${inapp}`
              )
            }
            goNext={() =>
              history.push(
                `/grunner/signup/complete_registration/?tokenId=${tokenId}&inapp=${inapp}`
              )
            }
          />
          {/* )} */}
          {identityInformation.templateId &&
            identityInformation.environment &&
            identityInformation.referenceId && (
              <Persona.Inquiry
                className={styles["persona-flow"]}
                templateId={identityInformation.templateId}
                environment={identityInformation.environment}
                referenceId={identityInformation.referenceId}
                onLoad={() => {}}
                onComplete={({ inquiryId, status, fields }) => {
                  history.push(
                    `/grunner/signup/background_check/start/?tokenId=${tokenId}&inapp=${inapp}`
                  );
                }}
              />
            )}
        </>
      )}
    </>
  );
};

export default IdentityVerification;
