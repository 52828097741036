import React from 'react';
import './NavbarChat.css';

const NavbarChat = ({ appbar }) => {
  let appbarCSS = 'main-header-chat';
  if (appbar) appbarCSS = 'main-header-chat-show';

  return (
    <>
      <header className={appbarCSS}>
        <img
          //src='https://sendagesture.com/static/media/New_Gesture_Logo.cfb01162.png'
          src='https://static.wixstatic.com/media/1e4717_b818d70ab4f349ceb2197b49f276fa52~mv2.png/v1/fill/w_57,h_57,al_c,q_85,usm_0.66_1.00_0.01/Gesture%20Logo-03.webp'
          alt='Gesture Logo'
        />
      </header>

      <div className='space-below-navbar'></div>
    </>
        
  );
};

export default NavbarChat;
