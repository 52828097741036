export const getToken = () => {
  const search = window.location.search;
  const parameters = new URLSearchParams(search);
  const tokenId = parameters.get("tokenId");
  return tokenId || localStorage.getItem("tokenId");
};

/**
 * Extracts tokenId from the url and stores it in sessionStorage
 * @returns {String} tokenId
 */
export const extractAndStoreTokenId = () => {
  // Automatically store or update the token from the url to sessionStorage
  const search = window.location.search;
  const parameters = new URLSearchParams(search);
  const tokenId = parameters.get("tokenId");
  if (tokenId) {
    sessionStorage.setItem("tokenId", tokenId);
    // Remove the tokenId from the url search params
    // parameters.delete("tokenId");
    // window.history.replaceState(
    //   Object.fromEntries(parameters),
    //   document.title,
    //   window.location.pathname + "?" + parameters.toString()
    // );
  }
  return tokenId;
};
