import axios from "axios";
import { mainAPIUrl } from "../../../settings";

let version = "/v2/";
let axiosInstance = null;
let socialInstance = null;

/**
 * Clears specific items from sessionStorage and refreshes the page.
 */
// const clearCacheAndRefresh = () => {
//   clearCache();

//   window.location.reload();
//   return;
// };

/**
 * Clears specific items from sessionStorage.
 * @returns {void}
 */
const clearCache = () => {
  for (let key of ["scrollPosition", "feedStorage", "tokenId"]) {
    if (sessionStorage.getItem(key)) sessionStorage.removeItem(key);
  }

  return;
};

/**
 * Fetch the report reasons from the API.
 * @returns {Promise<Array>} A promise that resolves to the report reasons.
 * @throws {Error} If the API call fails.
 */
export const fetchReportReasonsAPICall = async () => {
  const res = await getSocialAPI().get(`/report/reasons`);

  return res.data.data;
};

/**
 * Fetch the block reasons from the API.
 * @returns {Promise<Array>} A promise that resolves to the block reasons.
 * @throws {Error} If the API call fails.
 * @returns {Promise<Array>} A promise that resolves to the block reasons.
 */
export const fetchBlockReasonsAPICall = async () => {
  const res = await getSocialAPI().get(`/blocked/reasons`);

  return res.data.data;
};

/**
 * Fetches feeds from the API.
 * @returns {Promise<Array>} A promise that resolves to the current feeds.
 */
export const fetchFeedsAPICall = async () => {
  let url = `/feed`;

  console.debug("[APIManager] Fetching feeds from API", getSocialAPI());

  const res = await getSocialAPI().get(url);
  const currentFeeds = res.data.data;

  return currentFeeds;
};

/**
 * Fetches private feeds from the API.
 * @returns {Promise<Array>} A promise that resolves to the current private feeds.
 */
export const fetchPrivateFeedsAPICall = async () => {
  let url = `/feed/private`;

  const res = await getSocialAPI().get(url);
  const currentFeeds = res.data.data;

  return currentFeeds;
};

/**
 * Fetches older feeds from the API.
 * @param {string} paginateKey - The key used for pagination.
 * @returns {Promise<Array>} A promise that resolves to the older feeds.
 */
export const fetchOlderFeedsAPICall = async (paginateKey) => {
  let url = `/feed?paginateKey=${paginateKey}`;

  const res = await getSocialAPI().get(url);

  const olderFeeds = res.data.data.sort((a, b) => {
    return b.creationTime - a.creationTime;
  });

  return olderFeeds;
};

/**
 * Fetches user ID and name from the API.
 * @returns {Promise<Object>} A promise that resolves to the user data.
 */
export const getUserIdAndNameAPICall = async () => {
  const userRes = await getSocialAPI().get(`/tokens`);

  if (!userRes) return;

  return userRes;
};

/**
 * Fetches messages from the API.
 * @param {string} feedID - The ID of the feed.
 * @param {string} paginateKey - The key used for pagination.
 * @returns {Promise<Array>} A promise that resolves to the messages.
 */
export const getMessagesAPICall = async (feedID, paginateKey) => {
  const res = await getSocialAPI().get(
    `/content/feed-post/${feedID}/messages?paginateKey=${paginateKey}`
  );

  const messages = res.data.data.messages.sort((a, b) => {
    return a.sorter - b.sorter;
  });

  const data = [...messages];
  console.log("returning messages");

  return data;
};

/**
 * Fetches older messages from the API.
 * @param {string} feedID - The ID of the feed.
 * @param {string} paginateKey - The key used for pagination.
 * @returns {Promise<Array>} A promise that resolves to the older messages.
 */
export const fetchOlderMessagesAPICall = async (feedID, paginateKey) => {
  const res = await getSocialAPI().get(
    `/content/feed-post/${feedID}/messages?paginateKey=${paginateKey}`
  );

  const olderMessages = res.data.data.messages.sort((a, b) => {
    return a.sorter - b.sorter;
  });

  let newOlderMessages = [...olderMessages];

  for (let i = 0; i < newOlderMessages.length; i++) {
    let newKeyValuePairs = {
      profilePic: "https://i.imgur.com/f5f4aJl.jpg",
      sticker: "https://i.imgur.com/PWxBAx4.png",
    };
    newOlderMessages[i] = { ...newOlderMessages[i], ...newKeyValuePairs };
  }

  // return olderMessages

  return newOlderMessages;
};

/**
 * Sets the user name by fetching it from the API.
 * @returns {Promise<Object>} A promise that resolves to the user data.
 */
export const setUserNameAPICall = async () => {
  const userRes = await getSocialAPI().get(`/tokens`);

  if (!userRes) return;

  return userRes;
};

/**
 * Posts a new message to the API.
 * @param {string} contentId - The ID of the content.
 * @param {Object} newMessage - The new message to post.
 * @param {string} [contentType="feed"] - The type of content.
 * @returns {Promise<Object>} A promise that resolves to the response data.
 */
export const postMessageAPICall = async (
  contentId,
  newMessage,
  contentType = "feed-post"
) => {
  console.log("[APIManager] Posting message to API", {
    newMessage,
    contentId,
    contentType,
  });
  const res = await getSocialAPI().post(
    `/content/${contentType}/${contentId}/messages`,
    newMessage
  );

  return res;
};

/**
 * Handles feed privacy settings by posting to the API.
 * @param {Object} props - The properties for the API call.
 * @param {string} props.contentType - The type of content.
 * @param {string} props.contentId - The ID of the content.
 * @param {boolean} props.ispublic - The privacy setting.
 * @returns {Promise<Object>} A promise that resolves to the response data.
 */
export const handleFeedPrivacyAPICall = async (props) => {
  const res = await getSocialAPI().post(
    `/content/${props.contentType}/${props.contentId}/privacy`,
    { privacy: props.ispublic }
  );

  return res;
};

/**
 * Handles report submission by posting to the API.
 * @param {Object} props - The properties for the API call.
 * @param {string} props.contentType - The type of content.
 * @param {string} props.contentId - The ID of the content.
 * @param {string} props.ownerid - The ID of the content owner.
 * @param {string} props.reasonId - The reason ID.
 * @param {string} props.text - The report text.
 * @returns {Promise<Object>} A promise that resolves to the response data.
 */
export const handleReportSubmitAPICall = async (props) => {
  const res = await getSocialAPI().post(
    `/report/${props.contentType}/${props.contentId}`,
    {
      text: props.text,
      ownerId: props.ownerId,
      reasonId: props.reasonId,
      parentId: props.parentId,
      parentType: props.parentType,
    }
  );

  return res;
};

/**
 * Handles block user submission by posting to the API.
 * @param {Object} props - The properties for the API call.
 * @param {string} props.userId - The ID of the user.
 * @param {string} props.reasonId - The reason ID.
 * @param {string} props.text - The block text.
 * @returns {Promise<Object>} A promise that resolves to the response data.
 */
export const handleBlockUserSubmitAPICall = async (props) => {
  if (!props.userId) {
    console.error("[APIManager] handleBlockUserSubmitAPICall: ", props);
    throw new Error(
      "[APIManager] handleBlockUserSubmitAPICall: userId is required"
    );
  }
  const res = await getSocialAPI().post(`/blocked/users/${props.userId}`, {
    text: props.text,
    reasonId: props.reasonId,
  });

  return res;
};

/**
 * Create an axios instance with the main API url and the tokenId
 * @type {AxiosInstance}
 */
export const getAxiosInstance = () => {
  if (!axiosInstance) {
    const headers = { "Content-Type": "application/json" };

    if (sessionStorage.getItem("tokenId")) {
      headers["Authorization"] = `Bearer ${sessionStorage.getItem("tokenId")}`;
    }
    axiosInstance = axios.create({
      baseURL: mainAPIUrl,
      headers,
    });

    // Add interceptors to clear the cache if a 401 is received
    axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        console.log("[APIManager] Error response", error);
        if (
          sessionStorage.getItem("tokenId") &&
          error.response &&
          error.response.status === 401
        ) {
          clearCache();
        }
        return Promise.reject(error);
      }
    );
  }

  return axiosInstance;
};

/**
 * Get the social API instance
 * @param {String} baseURL
 * @returns {AxiosInstance}
 */
export const getSocialAPI = (baseURL) => {
  if (!socialInstance) {
    socialInstance = axios.create({
      ...getAxiosInstance().defaults,
      baseURL: baseURL || `${mainAPIUrl}/social${version}`,
    });

    // Add interceptors to clear the cache if a 401 is received
    socialInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        console.log("[APIManager] Error response", error);
        if (
          sessionStorage.getItem("tokenId") &&
          error.response &&
          error.response.status === 401
        ) {
          clearCache();
        }
        return Promise.reject(error);
      }
    );
    console.debug("[APIManager] Created social API instance", socialInstance);
  }
  return socialInstance;
};
