import { useState } from "react";
import { Modal } from "react-bootstrap";
import ReportModal from "./ReportModal";
import { v4 as uuid } from "uuid";
// import { handleFeedPrivacyAPICall } from '../../env/APImanager';

import "./ReportContentModal.css";
import { getSocialAPI } from "../../env/APImanager";
import BlockUserModal from "./BlockUserModal";

const ReportContent = (props) => {
  const [reportModalShow, setReportModalShow] = useState(false);
  const [blockUserShow, setBlockUserShow] = useState(false);
  const [contentType] = useState(props.contenttype);
  const [contentId] = useState(props.contentid);
  const [parentId] = useState(props.parentid);
  const [parentType] = useState(props.parenttype);
  const [reportContentType, setReportContentType] = useState(contentType);
  const [reportContentId, setReportContentId] = useState(contentId);
  const [userId] = useState(props.contentuid);
  const [currentUserId] = useState(props.currentuid);
  const [isPostPublic, setIsPostPublic] = useState(props.ispublic);

  const openReportModal = () => {
    setReportModalShow(true);
  };

  const openBlockUserModal = () => {
    setBlockUserShow(true);
  };

  const closeReportModal = () => {
    setReportModalShow(false);
    props.onHide();
  };

  const closeBlockUserModal = () => {
    setBlockUserShow(false);
    props.onHide();
  };

  const onReportPost = () => {
    setReportContentType("feed-post");
    setReportContentId(props.contentid);
    openReportModal();
  };

  const onBlockUser = () => {
    setReportContentType("user");
    setReportContentId(props.contentuid);
    openBlockUserModal();
  };

  const onReportComment = () => {
    setReportContentType("feed-post-comment");
    setReportContentId(props.contentid);
    openReportModal();
  };

  const onSuccessfulReport = (contentType, contentId) => {
    props.onSuccessfulReport &&
      props.onSuccessfulReport(contentType, contentId);
  };

  const onSuccessfulBlockUser = (contentType, contentId) => {
    props.onSuccessfulBlockUser &&
      props.onSuccessfulBlockUser(contentType, contentId);
  };

  const handleFeedPrivacy = async () => {
    await getSocialAPI().post(
      `/content/${props.contenttype}/${props.contentid}/privacy`,
      {
        privacy: isPostPublic,
      }
    );

    // const res = await handleFeedPrivacyAPICall(props)

    setIsPostPublic(!isPostPublic);
    props.onSuccessfulPrivacyChange &&
      props.onSuccessfulPrivacyChange(contentType, contentId);
    props.onHide();
    // window.location.reload()
  };

  return (
    <>
      <ReportModal
        key={uuid()}
        show={reportModalShow}
        contenttype={reportContentType}
        contentid={reportContentId}
        parentid={parentId}
        parenttype={parentType}
        ownerid={userId}
        currentuserid={currentUserId}
        reasons={props.reportReasons}
        onHide={() => closeReportModal()}
        onSuccessfulReport={onSuccessfulReport}
      />

      <BlockUserModal
        key={uuid()}
        show={blockUserShow}
        userid={reportContentId}
        reasons={props.blockReasons}
        onHide={() => closeBlockUserModal()}
        onSuccessfulBlockUser={onSuccessfulBlockUser}
      />

      <Modal
        {...props}
        centered
        aria-labelledby="contained-modal-title-vcenter"
        className="report-content-modal-container rounded-xl"
        animation={false}
      >
        <div className="report-content-modal-buttons-container">
          {props.isloggedin ? (
            <Modal.Body>
              {props.children}
              {userId !== currentUserId && (
                <button
                  className="report-content-modal-each-button block"
                  onClick={() => onBlockUser()}
                >
                  Block User
                </button>
              )}
              {contentType === "feed-post" && currentUserId === userId ? (
                <button
                  className="report-content-modal-each-button block"
                  onClick={handleFeedPrivacy}
                >
                  {isPostPublic ? "Make Private" : "Make Public"}
                </button>
              ) : (
                contentType === "feed-post" && (
                  <button
                    className="report-content-modal-each-button block"
                    onClick={() => onReportPost()}
                  >
                    Report Post
                  </button>
                )
              )}
              {contentType === "feed-post-comment" &&
                userId !== currentUserId && (
                  <button
                    className="report-content-modal-each-button block"
                    onClick={() => onReportComment()}
                  >
                    Report Comment
                  </button>
                )}

              <button
                className="report-content-modal-each-button block cancel"
                onClick={props.onHide}
              >
                Cancel
              </button>
            </Modal.Body>
          ) : (
            <></>
          )}
          {!props.isloggedin ? (
            <Modal.Body>
              <p className="report-content-modal-login-text">
                Log in to ensure effective reporting and follow-up
              </p>
              <button
                className="report-content-modal-each-button block"
                onClick={props.onHide}
              >
                Close
              </button>
            </Modal.Body>
          ) : (
            <></>
          )}
        </div>
      </Modal>
    </>
  );
};

export default ReportContent;
