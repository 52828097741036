import React, { useEffect, useState } from "react";
import NavBar from "../NavBar/NavBar";
import ClipLoader from "react-spinners/ClipLoader";
import gLinkStyles from "./GLink.module.css";
// import linkCreate from "../../assets/Jumpingif1.gif";
import linkCreate from "../../assets/Glink.gif";
import linkReady from "../../assets/linkReady.png";

import { getLink } from "../../env/APIManager";

let search, params, tokenId, view, appbar, orderId, redeemed;

const GLink = (props) => {
  search = window.location.search;
  params = new URLSearchParams(search);
  orderId = params.get("orderId");
  tokenId = params.get("tokenId");
  view = params.get("view");
  appbar = params.get("appbar");
  redeemed = params.get("redeemed");
  const [loaded, setLoaded] = useState(false);
  const [linkLoaded, setlinkLoaded] = useState(false);
  const [link, setLink] = useState("-");
  const [message, setMessage] = useState("-");

  const copyStringWithNewLineToClipBoard = (stringWithNewLines) => {
    var copyText = document.getElementById("glink");
    var textArea = document.createElement("textarea");
    textArea.value = copyText.textContent;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("Copy");
    textArea.remove();

    var text = document.getElementById("copyBtnText");
    text.innerHTML = "Copied";
    var btn = document.getElementById("copyBtn");
    btn.style.background = "#A00831";
    setTimeout(function () {
      text.innerHTML = "Copy Link";
      btn.style.background = "#2B08A0";
    }, 1200);
  };

  useEffect(() => {
    const getlink = async () => {
      await getLink(orderId)
        .then((res) => {
          if (res && res.code.code === "SUCCESS" && res.code.data) {
            setLink(res.code.data.link);
            setMessage(res.code.data.message);
            setlinkLoaded(true);
          }
          setLoaded(true);
        })
        .catch((err) => console.log(err));
    };
    getlink();
    //setLoaded(true);
  }, []);

  useEffect(() => {
    const getlink = async () => {
      await getLink(orderId)
        .then((res) => {
          if (res.code.code === "SUCCESS" && res.code.data) {
            setLink(res.code.data.link);
            setMessage(res.code.data.message);
            setlinkLoaded(true);
            clearInterval(interval);
          }
        })
        .catch((err) => console.log(err));
    };
    const interval = setInterval(() => {
      //setlinkLoaded(true);
      getlink();
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={gLinkStyles.StyledPage}>
      {!loaded ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "45vh",
          }}
        >
          <ClipLoader size={100} color="#2B08A0" />
        </div>
      ) : linkLoaded === false ? (
        <>
          {appbar === "true" ? (
            <NavBar
              helpBtn={true}
              backBtn={true}
              orderId={orderId}
              tokenId={tokenId}
              view={view}
              screen={"track"}
              header={"G-Link"}
            />
          ) : (
            <div></div>
          )}
          <div className={gLinkStyles.outer}>
            <div className={gLinkStyles.container}>
              <img
                alt="your special link is being created."
                src={linkCreate}
                className={gLinkStyles.linkCreatedImg}
              ></img>
              <span className={gLinkStyles.text}>
                Your special link is being created.
              </span>
            </div>
          </div>
        </>
      ) : (
        <>
          {appbar === "true" ? (
            <NavBar
              helpBtn={true}
              backBtn={true}
              orderId={orderId}
              tokenId={tokenId}
              view={view}
              screen={"track"}
              header={"G-Link"}
            />
          ) : (
            <div></div>
          )}
          <div className={gLinkStyles.outer}>
            <div className={gLinkStyles.container}>
              <img
                alt="your link is ready."
                src={linkReady}
                className={gLinkStyles.linkReadyImg}
              ></img>
              <span className={gLinkStyles.linkReadytext}>
                Your link is ready!
              </span>
              <div className={gLinkStyles.linkBox}>
                <span
                  id="glink"
                  style={{
                    position: "absolute",
                    left: "-1000px",
                    top: "-1000px",
                    whiteSpace: "pre-line",
                  }}
                >
                  {message}
                </span>
                <span className={gLinkStyles.link}>{link}</span>
              </div>
              <div
                id="copyBtn"
                className={gLinkStyles.copyBtn}
                onClick={() => copyStringWithNewLineToClipBoard(message)}
              >
                <span id="copyBtnText" className={gLinkStyles.copyBtnText}>
                  Copy
                </span>
              </div>

              {view === "recipient" || !redeemed ? (
                <></>
              ) : redeemed === "false" ? (
                <span className={gLinkStyles.notRedeemed}>
                  Your link is not redeemed
                </span>
              ) : (
                <span className={gLinkStyles.redeemed}>
                  Your link has been redeemed
                </span>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default GLink;
