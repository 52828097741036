import React, { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import AppBar from "./AppBar";
import { useHistory } from "react-router-dom";

function PS() {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const inapp = params.get("inapp") || false;

  const history = useHistory();

  let url = "https://help.gesture.vip/m-grunner-privacy-policy/";

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);
  return (
    <div style={{ width: "100%", overflowY: "hidden" }}>
      {!loaded ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "45vh",
          }}
        >
          <ClipLoader size={100} color="#2B08A0" />
        </div>
      ) : (
        <>
          <AppBar
            onClickAction={() => {
              history.goBack(`?inapp=${inapp}`);
            }}
            title={"Privacy Policy"}
          />

          <iframe
            title="Privacy Policy"
            src={url}
            width="100%"
            style={{ border: "none", overflow: "auto", height: "90%" }}
          ></iframe>
        </>
      )}
    </div>
  );
}

export default PS;
