// start of all imports needed
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";
import ClipLoader from "react-spinners/ClipLoader";
import AppBar from "./appbar/AppBar";
import styles from "./PhoneVerify.module.css";
import InputNumber from "./Inputs/InputNumber";
import InputVerificationCode from "./Inputs/InputVerificationCode";
import { toast, ToastContainer } from "react-toastify";
import { sendCode, verifyCode } from "../../env/API";
import "react-toastify/dist/ReactToastify.css";
import desktopStyling from "../DesktopStyles.module.css";

//end of imports
// start of the component no props passed
const PhoneVerify = () => {
  // extracting push from useHistory to navigate pages
  const { push } = useHistory();
  // location object with state passed from previous signup page
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const tokenId = params.get("tokenId");
  const inapp = params.get("inapp") || false;

  // declaration and initialization of states needed for component to operate
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [error, setError] = useState("");
  const [errorCode, setErrorCode] = useState("");
  const [toastAction, setToastAction] = useState(false);
  const [success, setSuccess] = useState(false);
  const [code, setCode] = useState("");

  // end of state declaration and initialization
  // validate phoneNumber function checks if it is phone number and displays too short or too long or invalid
  const validate = () => {
    //initialization of errorMsgs
    let errorMsgs = {};
    // checking if it is a phone number for US
    errorMsgs.userPhoneNumber =
      phoneNumber.replaceAll(/\s/g, "").replaceAll(/[{()}[\]]/g, "")?.length <
      10
        ? "Phone number is too short"
        : phoneNumber.replaceAll(/\s/g, "").replaceAll(/[{()}[\]]/g, "")
            ?.length > 10
        ? "Phone Number is too long"
        : /^[0-9]+$/.test(
            phoneNumber.replaceAll(/\s/g, "").replaceAll(/[{()}[\]]/g, "")
          )
        ? ""
        : "Phone Number is not valid. ";
    setError(errorMsgs.userPhoneNumber);
  }; //end of validate phone number
  // handleSendCode is on click for button when the success is true
  const handleSendCode = async (event) => {
    // setting loader to true
    setLoading(true);

    // checking there are errors if there are none then will display toast
    // else it calls sendCode function that takes tokenId, phoneNumber
    if (error) {
      toast.error(error);
      setToastAction(true);
      setLoading(false);
    } else {
      const res = await sendCode(
        tokenId,
        phoneNumber.replaceAll(/\s/g, "").replaceAll(/[{()}[\]]/g, "")
      );
      // if successful request sets success to true that changes component to verify code
      // else displays toast with error
      if (res?.data?.code === "SUCCESS") {
        setSuccess(true);
      } else if (res?.data?.code === "ERROR") {
        if (res?.data?.data?.message === "TWILIO_ERROR") {
          setToastAction(true);
          toast.error(
            "Please verify that the number you have entered is valid and try again"
          );
        } else {
          toast.error(
            "Error! please try again later or different phone number."
          );
          setToastAction(true);
        }
      } else {
        toast.error("Error! please try again later or different phone number.");
        setToastAction(true);
      }
      //set loading to false
      setLoading(false);
    }
  };
  // start of
  const handleVerifyCode = async () => {
    setLoading(true);

    const resCode = await verifyCode(
      tokenId,
      phoneNumber.replaceAll(/\s/g, "").replaceAll(/[{()}[\]]/g, ""),
      code
    );
    if (resCode?.data?.code === "SUCCESS") {
      push({
        pathname: `/grunner/signup/address_selection/?tokenId=${tokenId}&inapp=${inapp}`,
        state: resCode.data.data.user,
      });
    } else if (resCode?.data?.code === "ERROR") {
      if (resCode?.data?.data?.message === "INVALID_CODE") {
        setToastAction(true);
        toast.error(
          "Please verify that the code you have entered is correct and try again"
        );
        setErrorCode("Wrong code.");
        setToastAction(true);
        setLoading(false);
      } else if (resCode?.data?.data?.message === "PHONE_NOT_FOUND") {
        setToastAction(true);
        toast.error(
          "There has been an issue. Please re-enter your phone number on the previous screen and try again."
        );
        setError("");
        setErrorCode("");
        setToastAction(true);
        setLoading(false);
      } else {
        toast.error("Invalid Code try again");
        setErrorCode("Wrong code.");
        setToastAction(true);
        setLoading(false);
      }
    } else {
      toast.error("Invalid Code try again");
      setErrorCode("Wrong code.");
      setToastAction(true);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (phoneNumber.length > 0) {
      validate();
    }
  }, [phoneNumber]);
  return (
    <>
      {loading ? (
        <div className={styles["loading"]}>
          <ClipLoader size={100} color="#2B08A0" />
        </div>
      ) : (
        <div className={desktopStyling.mainContainer}>
          {!success ? (
            <>
              <AppBar
                hide
                onClickAction={() => {
                  push({ pathname: `/grunner/signup/new/?inapp=${inapp}` });
                }}
                title={"Phone Verify"}
              />
              <motion.div className={styles["main-container"]}>
                <InputNumber
                  field={"Phone Number"}
                  value={phoneNumber}
                  setValue={setPhoneNumber}
                  validate={validate}
                  error={error}
                />
                {toastAction ? <ToastContainer /> : <></>}
                <button
                  className={styles["next-button"]}
                  onClick={handleSendCode}
                >
                  Send Code
                </button>
              </motion.div>
            </>
          ) : (
            <>
              <AppBar
                onClickAction={(value) => {
                  setSuccess(value);
                  setCode("");
                }}
                title={"Phone Verify Code"}
              />
              <motion.div className={styles["main-container"]}>
                <InputVerificationCode
                  field={"SMS Code"}
                  value={code}
                  setValue={setCode}
                  error={errorCode}
                />
                {toastAction ? <ToastContainer /> : <></>}
                <button
                  className={styles["next-button"]}
                  onClick={handleVerifyCode}
                >
                  Verify Code
                </button>
              </motion.div>
            </>
          )}
        </div>
      )}
    </>
  );
};
export default PhoneVerify;
