import { ClipLoader } from "react-spinners";
import styles from "./BackgroundCheckComplete.module.css";

const BackgroundCheckComplete = () => {
  window.parent.postMessage("complete", "*");
  console.log("Opened component");

  return (
    <>
      <div className={styles["screen-loading"]}>
        <ClipLoader size={100} color="#2B08A0" />
      </div>
    </>
  );
};

export default BackgroundCheckComplete;
