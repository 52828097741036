import React, { useEffect, useState } from "react";
import { db } from "../../env/firebase";
import ClipLoader from "react-spinners/ClipLoader";
import ChatroomTextInput from "./ChatroomTextInput";
import ChatroomMessage from "./ChatroomMessage";
import NavbarChat from "../feedscreen/NavbarChat";
import { Link } from "react-router-dom";
import { ReactComponent as ChevronLeft } from "../../assets/Chevron_Left.svg";
import {
  // fetchOlderMessagesAPICall,
  fetchReportReasonsAPICall,
  fetchBlockReasonsAPICall,
  setUserNameAPICall,
  getMessagesAPICall,
  // fetchOlderMessagesAPICall,
} from "../../env/APImanager";

import "../../components/bootstrap.min.css";
import styles from "./Chatroom.module.css";
import { toast, ToastContainer } from "react-toastify";
import { extractAndStoreTokenId } from "../../../../utils";

extractAndStoreTokenId();

const Chatroom = ({ match }) => {
  const feedID = match.params.id;
  // const numOfMessagesPerLoad = 8;

  // const [hasMore, setHasMore] = useState(true); // for the Load more button display

  const [messages, setMessages] = useState([]); // all the messages for each feed
  const [messagesLoading, setMessagesLoading] = useState(false); // for the spinner when the first 8 messages are being loaded
  // const [loadingMore, setLoadingMore] = useState(false); // for the spinner when users click Load more button
  // const [hasMore, setHasMore] = useState(true); // for the Load more button display

  const [name, setName] = useState("");
  const [uid, setUid] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(0);
  const [reportReasons, setReportReasons] = useState([]);
  const [blockReasons, setBlockReasons] = useState([]);
  const [isContentReported, setIsContentReported] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [hasError, setHasError] = useState(false);

  let search = window.location.search;
  let parameter = new URLSearchParams(search);

  const feedOwnerUid = parameter.get("feedOwnerUid");

  const appbarRes = parameter.get("appbar") || false;

  const appbar = appbarRes === "true";

  const navbar = (parameter.get("navbar") || false) === "true";

  useEffect(() => {
    window.scrollTo(0, 0);
    handleSetUserName();
    handleMessages();
    fetchReportReasons();
    fetchBlockReasons();
  }, []);

  const fetchReportReasons = async () => {
    try {
      const reportReasonsRes = await fetchReportReasonsAPICall();

      setReportReasons(reportReasonsRes);
    } catch (error) {
      console.warn("[FeedScreen] fetchReportReasons error: ", error);
    }
  };

  const fetchBlockReasons = async () => {
    try {
      const blockReasonsRes = await fetchBlockReasonsAPICall();

      setBlockReasons(blockReasonsRes);
    } catch (error) {
      console.warn("[FeedScreen] fetchBlockReasons error: ", error);
    }
  };

  const handleMessages = async () => {
    let messagesList = [];

    console.log(
      "[Chatroom] handleMessages feedID: ",
      `/social_feed_messages/${feedID}`
    );
    let messagesRef = db.ref(`/social_feed_messages/${feedID}`).limitToLast(1);
    // .orderByChild("sorter")
    // .limitToFirst(numOfMessagesPerLoad);

    // TODO: The following wasn't working locally with a permission denied error. Don't have time to review this now
    // console.debug(
    //   "[Chatroom] handleMessages messagesRef: ",
    //   messagesRef,
    //   messagesRef.once("value", (snapshot) => {
    //     console.log("snapshot: ", snapshot.val());
    //   })
    // );

    setMessagesLoading(true);

    // const paginateKey = (Date.now() * -1).toString();
    const paginateKey = null;
    try {
      messagesList = await getMessagesAPICall(feedID, paginateKey); // will get the first 8 messages from API call, not with listener

      setMessages(messagesList);
    } catch (error) {
      setHasError(true);
      if (error.response.status === 401) {
        setIsLoggedIn(0);
      }
      if (error.response.data?.code === "CONTENT_REPORTED") {
        setIsContentReported(true);
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage(
          "We couldn't load the messages. Please try again later."
        );
      }
    }
    setMessagesLoading(false);

    messagesRef.on("child_added", (snapshot) => {
      console.debug("[Chatroom] handleMessages snapshot: ", snapshot.val());
      if (!snapshot.exists()) {
        setMessagesLoading(false);
        return null;
      } // do nothing and just return if there is no message

      let foundMessage = messagesList.find(
        (message) => message.id === snapshot.key
      );

      if (!foundMessage) {
        let obj = snapshot.val();
        obj["id"] = snapshot.key;
        messagesList.push(obj);
      }

      setMessages([]);
      setMessages(messagesList);
      setMessagesLoading(false);
    });

    return () => messagesRef.off();
  };

  // const getOlderMessages = async () => {
  //   setLoadingMore(true);

  //   const paginateKey = (
  //     messages && messages[messages.length - 1].sorter + 1
  //   ).toString();

  //   const olderMessages = await fetchOlderMessagesAPICall(feedID, paginateKey);

  //   if (olderMessages.length < numOfMessagesPerLoad) {
  //     setHasMore(false);
  //   }

  //   setMessages([...messages, ...olderMessages]);

  //   setLoadingMore(false);
  // };

  const handleSetUserName = async () => {
    try {
      const userRes = await setUserNameAPICall();

      setName(userRes.data.data.name);
      setUid(userRes.data.data.uid);
      setIsLoggedIn(1);
    } catch (error) {
      console.warn("[Chatroom] handleSetUserName error: ", error);
    }

    return true;
  };

  // On a successful report remove the given item from the list
  const onSuccessfulReport = (contentType, contentId) => {
    console.log("onSuccessfulReport", contentType, contentId);
    const newMessages = messages.filter((message) => message.id !== contentId);
    setMessages(newMessages);
    toast.success("Reported successfully");
  };

  // On a successful block refetch the feed and toast that the user was blocked
  const onSuccessfulBlockUser = () => {
    handleMessages();
    toast.success(
      "We've blocked this user. You'll no longer see their content."
    );
  };

  // This function is called when a new message is posted
  const onHandleNewMessage = (newMessage) => {
    console.debug("[Chatroom] onHandleNewMessage: ", newMessage);
    let newMessages = [newMessage, ...messages];

    setMessages(newMessages);
  };

  return (
    <>
      <NavbarChat appbar={appbar} />
      <ToastContainer />

      {!messagesLoading && !hasError ? (
        <section className={styles["main-comments"]}>
          {navbar && (
            <div className={styles["main-comments-go-back"]}>
              <Link
                to={`/feed?appbar=${appbar}`}
                className={styles["go-back-btn"]}
              >
                <div className={styles["go-back"]}>
                  <ChevronLeft />
                </div>
              </Link>

              <div className={styles["main-comments-sender-info"]}>
                <p className="post-fromto-info my-1 comments-sender-info">
                  Comments
                </p>
              </div>
            </div>
          )}
          {messagesLoading ? (
            <div id="messages-loading" className={styles["messages-loading"]}>
              <ClipLoader size={40} color="#2B08A0" />
            </div>
          ) : (
            <></>
          )}

          {/* <div className={styles["comments-load-more"]}>
          {hasMore &&
            !messagesLoading &&
            messages.length >= numOfMessagesPerLoad && (
              <button
                className={styles["load-more-button"]}
                onClick={getOlderMessages}
              >
                {loadingMore ? (
                  <div className={styles["more-messages-loading"]}>
                    <ClipLoader size={18} color="#fff" />
                  </div>
                ) : (
                  "Load more"
                )}
              </button>
            )}
        </div> */}

          <div id="comments" className={styles["comments"]}>
            <div
              id="comments-scrollable-area"
              className={styles["comments-scrollable-area"]}
            >
              <div className={styles["comments-area"]}>
                {messages.length === 0 && !messagesLoading ? (
                  <div style={{ textAlign: "center" }}>
                    <p className="mt-3">This feed has no messages yet.</p>
                  </div>
                ) : (
                  messages
                    .sort((a, b) => {
                      return a.sorter - b.sorter;
                    })
                    .map((msg) => (
                      <ChatroomMessage
                        key={msg.creationTime}
                        msg={msg}
                        isLoggedIn={isLoggedIn}
                        ispublic={1}
                        feedid={feedID}
                        uid={uid}
                        feedowneruid={feedOwnerUid}
                        blockReasons={blockReasons}
                        reportReasons={reportReasons}
                        onSuccessfulReport={onSuccessfulReport}
                        onSuccessfulBlockUser={onSuccessfulBlockUser}
                      />
                    ))
                )}
              </div>
            </div>

            {isLoggedIn ? (
              <div
                id="comments-input-container"
                className={styles["comments-input-container"]}
              >
                <ChatroomTextInput
                  shownavbar={navbar}
                  isLoggedIn={isLoggedIn}
                  contentId={feedID}
                  contentType="feed-post"
                  name={name}
                  uid={uid}
                  existingMessages={messages}
                  onHandleNewMessage={onHandleNewMessage}
                />
              </div>
            ) : (
              <></>
            )}
            {!isLoggedIn ? (
              <div
                style={{ textAlign: "center", width: "100%", padding: "10px" }}
              >
                <small>You must be logged in to comment</small>
              </div>
            ) : (
              <></>
            )}
          </div>
        </section>
      ) : (
        <></>
      )}
      {isContentReported ? (
        <div className="text-center mt-3">
          <h2>Messages Unavailable</h2>
          <p>{errorMessage}</p>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Chatroom;
