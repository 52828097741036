import React, { useState } from "react";
import { Nav } from "react-bootstrap";

import "./NavbarFeed.css";

const NavbarFeed = ({
  appbar,
  fetchFeeds,
  fetchPrivateFeeds,
  isLoggedIn,
  showPrivate,
}) => {
  let appbarCSS = "main-header-feed";
  let spaceBelowNavbarFeedCSS = "space-below-navbar-feed";

  const [tab, setTab] = useState("public");

  if (appbar) {
    appbarCSS = "main-header-feed-show";
    spaceBelowNavbarFeedCSS = "space-below-navbar-feed-show";
  }

  const resetScrollAndFetchPublic = () => {
    sessionStorage.removeItem("scrollPosition");
    fetchFeeds();
  };

  const resetScrollAndFetchPrivate = () => {
    sessionStorage.removeItem("scrollPosition");
    fetchPrivateFeeds();
  };

  const onSetTab = (k) => {
    setTab(k);
    if (k === "public") {
      resetScrollAndFetchPublic();
    } else {
      resetScrollAndFetchPrivate();
    }
    console.debug("Tab set to: ", k, "Fetching ...");
  };

  return (
    <>
      <header className={appbarCSS}>
        <div className="main-header-feed-content">
          {(appbar === "false" || !appbar) && (
            <img
              //src='https://sendagesture.com/static/media/New_Gesture_Logo.cfb01162.png'
              src="https://static.wixstatic.com/media/1e4717_b818d70ab4f349ceb2197b49f276fa52~mv2.png/v1/fill/w_57,h_57,al_c,q_85,usm_0.66_1.00_0.01/Gesture%20Logo-03.webp"
              alt="Gesture Logo"
            />
          )}

          <Nav
            justify
            defaultActiveKey="public"
            id="gfeed-tabs"
            className="feed-tabs"
            variant="underline"
            onSelect={(k) => onSetTab(k)}
            activeKey={tab}
          >
            <Nav.Item>
              <Nav.Link eventKey="public" title="Public Posts">
                Public Posts
              </Nav.Link>
            </Nav.Item>
            {isLoggedIn ? (
              <Nav.Item>
                <Nav.Link eventKey="private" title="My Posts">
                  My Posts
                </Nav.Link>
              </Nav.Item>
            ) : (
              <></>
            )}
          </Nav>
        </div>
      </header>

      <div className={spaceBelowNavbarFeedCSS}></div>
    </>
  );
};

export default NavbarFeed;
