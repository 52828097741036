import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import ClipLoader from "react-spinners/ClipLoader";
import { postMessageAPICall } from "../../env/APImanager";
import { ReactComponent as SendButton } from "../../assets/Send_Button.svg";

import styles from "./Chatroom.module.css";

const ChatroomTextInput = ({
  contentId,
  contentType,
  name,
  uid,
  onHandleNewMessage,
}) => {
  const [posting, setPosting] = useState(false); // for the spinner when users click Post button
  const [message, setMessage] = useState(""); // message - text input

  const postMessageHandler = async (e) => {
    e.preventDefault();
    setPosting(true);

    try {
      const newMessage = {
        message,
        creationTime: Date.now(),
        sorter: Date.now() * -1,
        composerName: name,
        ownerUid: uid,
      };

      const result = await postMessageAPICall(
        contentId,
        newMessage,
        contentType
      );
      console.debug("[ChatroomTextInput] postMessageHandler: ", result);
      onHandleNewMessage && onHandleNewMessage(result.data.data.message || {});

      setMessage("");

      document.getElementById("comments-scrollable-area").scrollTo(0, 0); // scroll to top after a new message is posted
    } catch (error) {
      console.error(error.message);
    }
    setPosting(false);
  };

  return (
    <>
      <div className={styles["main-comments-top"]}>
        <div className={styles["comment-form"]}>
          <Form
            className={styles["comment-send-form"]}
            onSubmit={postMessageHandler}
          >
            <input
              type="text"
              name="text"
              value={message}
              style={{ fontSize: "16px" }}
              placeholder="Say something..."
              className={styles["comment-form-input"]}
              onChange={(e) => setMessage(e.target.value)}
            />

            <Button
              className={styles["msg-send-button"]}
              type="submit"
              disabled={message.length === 0 || posting}
            >
              {posting ? (
                <span>
                  <ClipLoader size={20} color="#2B08A0" />
                </span>
              ) : (
                <span>
                  <SendButton />
                </span>
              )}
            </Button>
          </Form>
        </div>
      </div>
    </>
  );
};

export default ChatroomTextInput;
