import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import List from "@mui/material/List";
import ClipLoader from "react-spinners/ClipLoader";
import { getAllTasksByTokenId } from "../../env/API.js";
import styles from "./TaskList.module.css";
import TaskView from "./TaskView/TaskView.jsx";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";

const CustomDivider = styled(Divider)`
  background: #f4f4f4;
`;

const TaskList = () => {
  const [loading, setLoading] = useState(true);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const tokenId = params.get("tokenId");
  const getTasks = async () => {
    return await getAllTasksByTokenId(tokenId);
  };
  const [tasks, setTasks] = useState([]);
  const getAllTasks = async () => {
    setLoading(true);
    const taskData = await getTasks(tokenId);
    if (taskData?.data?.data) setTasks(taskData?.data?.data?.tasks);
    setLoading(false);
  };
  useEffect(() => {
    setLoading(true);
    getAllTasks();
  }, []);
  console.log(tasks);
  return (
    <>
      <motion.div className={styles["main-container"]}>
        {loading ? (
          <div className={styles["spinner"]}>
            <ClipLoader size={100} color="#2B08A0" />
          </div>
        ) : (
          <>
            <CustomDivider />
            {tasks.length > 0 ? (
              <div className={styles["tasks-List"]}>
                <List>
                  {tasks.map((task, index) => {
                    return (
                      <div key={index}>
                        <TaskView task={task} key={"taskview" + index} />
                        <CustomDivider key={"divider" + index} />
                      </div>
                    );
                  })}
                </List>
              </div>
            ) : (
              <div className={styles["tasks-text-container"]}>
                <h3 className={styles["tasks-text-title"]}>Note</h3>
                <span className={styles["tasks-text"]}>
                  You haven't completed any tasks yet. You can view your tasks
                  after you complete them on this page.
                </span>
              </div>
            )}
          </>
        )}
      </motion.div>
    </>
  );
};
export default TaskList;
