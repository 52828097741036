import { Modal } from "react-bootstrap";
// import { handleFeedPrivacyAPICall } from '../../env/APImanager';
import { ReactComponent as ChevronLeft } from "../../assets/Chevron_Left.svg";

import "./FeedChatModal.css";
// import { useState } from "react";

const FeedChatModal = (props) => {
  // const [firstLoad, setFirstLoad] = useState(true);

  const onSetHeight = () => {
    const iframe = document.querySelector(`#feed-chat-frame-${props.feedid}`);
    const interval = setInterval(() => {
      checkAndSetIframeHeight();
    }, 100);
    checkAndSetIframeHeight();

    function checkAndSetIframeHeight() {
      // console.log(iframe.contentWindow.document.body.querySelector('#messages-loading'));
      if (
        iframe.contentWindow.document.body.querySelector(
          "#messages-loading"
        ) === null
      ) {
        iframe.style.height =
          iframe.contentWindow.document.body.scrollHeight + 170 + "px";
        console.log("Iframe height", iframe.style.height);
        clearInterval(interval);
      }
    }
    // setFirstLoad(false);
  };
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="feed-chat-modal-container"
      animation={true}
    >
      <div className="feed-chat-modal-buttons-container">
        <Modal.Header>
          <ChevronLeft onClick={() => props.onHide()} /> <span>Comments</span>
        </Modal.Header>
        <Modal.Body>
          <iframe
            id={`feed-chat-frame-${props.feedid}`}
            src={props.url + "&navbar=false"}
            title="Feed Chat"
            className="feed-chat-iframe"
            allow="microphone; camera"
            allowFullScreen
            style={{
              height: "0px",
              minHeight: "250px",
              maxHeight: "90vh",
              border: "none",
              borderBottomLeftRadius: "20px",
              borderBottomRightRadius: "20px",
            }}
            onLoad={onSetHeight}
          />
        </Modal.Body>
        {/* <Modal.Footer></Modal.Footer> */}
      </div>
    </Modal>
  );
};

export default FeedChatModal;
