import { useState } from "react";
import { Alert, DropdownButton, Modal } from "react-bootstrap";
import { handleBlockUserSubmitAPICall } from "../../env/APImanager";
import "./BlockUserModal.css";
import DropdownItem from "react-bootstrap/esm/DropdownItem";

const BlockUserModal = (props) => {
  const [text, setText] = useState("");
  const [reasons] = useState(props.reasons);
  const [reasonId, setReasonId] = useState("");
  const [currentReason, setCurrentReason] = useState("");
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const onSetReasonId = (id) => {
    setReasonId(id);
    setCurrentReason(reasons.find((reason) => reason.id === id).text);
  };

  const handleBlockUserSubmit = async (e) => {
    e.preventDefault();
    setIsError(false);
    setIsLoading(true);
    // If we're successful just close the modal
    if (isSuccessful) {
      props.onHide();
      return;
    }
    try {
      console.debug("[BlockUserModal] handleBlockUserSubmit: ", props, {
        text,
        reasonId,
        userId: props.userid,
      });
      await handleBlockUserSubmitAPICall({
        text,
        reasonId,
        userId: props.userid,
      });
      setIsSuccessful(true);
      props.onSuccessfulBlockUser &&
        props.onSuccessfulBlockUser("user", props.userid);
    } catch (error) {
      setIsError(true);
      setErrorMessage(error.response?.data?.message || error.message);
      console.warn(
        "[BlockUserModal] handleBlockUserSubmit error: ",
        error.response
      );
    }
    setIsLoading(false);

    // props.onHide();
    // window.location.reload();
  };

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop={false}
      animation={true}
      className="report-modal-gfeed"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <p className="modal-title">
            BlockUser{" "}
            <span style={{ textTransform: "capitalize" }}>
              {props.contenttype}
            </span>
          </p>
        </Modal.Title>
      </Modal.Header>

      <form
        onSubmit={handleBlockUserSubmit}
        className="report-modal-form-gfeed"
      >
        <Modal.Body>
          <div className="block-user-container">
            {isError && (
              <div className="block-user-error" style={{ width: "100%" }}>
                <Alert show={isError} variant="warning">
                  {errorMessage}
                </Alert>
              </div>
            )}
            {isSuccessful && (
              <div className="block-user-success" style={{ width: "100%" }}>
                <Alert show={isSuccessful} variant="success">
                  BlockUser submitted successfully!
                </Alert>
              </div>
            )}
            {!isSuccessful && (
              <>
                <div className="block-user-reason">
                  <DropdownButton
                    button
                    block
                    className="block-user-reason-id"
                    title={currentReason || "Please select a reason ..."}
                    size="lg"
                  >
                    {reasons.map((reason, index) => (
                      <DropdownItem
                        key={index}
                        onClick={() => onSetReasonId(reason.id)}
                      >
                        {reason.text}
                      </DropdownItem>
                    ))}
                  </DropdownButton>
                </div>
                <div className="block-user-text-area">
                  <div className="input">
                    <textarea
                      autoresize="true"
                      cols="30"
                      type="text"
                      id="input"
                      value={text}
                      onChange={(e) => setText(e.target.value)}
                      className="input-text"
                      placeholder="What seems to be the matter?"
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </Modal.Body>

        <Modal.Footer className="report-footer-area">
          <div className="block-user-buttons">
            {!isSuccessful && (
              <button
                className="block-user-each-button block-user-button cancel"
                onClick={props.onHide}
              >
                Cancel
              </button>
            )}
            <button
              className="block-user-each-button block-user-button submit"
              disabled={reasonId === ""}
            >
              {isLoading ? "Submitting..." : isSuccessful ? "Done" : "Submit"}
            </button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default BlockUserModal;
