import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import html2canvas from "html2canvas";
import OpenInFullIcon from "@mui/icons-material/OpenInFullRounded";
import CloseIcon from "@mui/icons-material/CloseRounded";
import ClipLoader from "react-spinners/ClipLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import MessageModal from "./MessageModal";
import Footer from "./Footer";
import "./PostCard.css";
import postCardLogo from "../../assets/post-card-logo.png";
import postCardNames from "../../assets/post-card-names.png";
import postCardSelfie from "../../assets/post-card-selfie.png";
import postCardMessage from "../../assets/post-card-message.png";
import postCardDoodle from "../../assets/post-card-doodle.png";

const PostCard = React.forwardRef(
  ({ message, preview, fallbackAssets }, ref) => {
    const [selfieFull, setSelfieFull] = useState(false);
    const [shareLoading, setShareLoading] = useState(false);
    const [messageModalOpen, setMessageModalOpen] = useState(false);

    const errorToastOptions = {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    };

    const selfieVariant = {
      standard: {
        zIndex: 2,
        display: "flex",
        alignItems: "center",
        overflow: "hidden",
        top: "5%",
        width: "50%",
        height: "84%",
        borderRadius: "100%",
      },
      full: {
        zIndex: 4,
        display: "block",
        alignItems: "normal",
        overflow: "visible",
        top: 0,
        width: "100%",
        height: "100%",
        borderRadius: "0",
      },
    };

    const handleSelfieFull = () => {
      window.scrollTo(0, 0);
      setSelfieFull(true);
      document.addEventListener("mousedown", handleCloseSelfieFull);
    };

    const handleCloseSelfieFull = () => {
      window.scrollTo(0, 0);
      setSelfieFull(false);
      document.removeEventListener("mousedown", handleCloseSelfieFull);
    };

    const openMessageModal = () => {
      window.scrollTo(0, 0);
      document.body.style.overflow = "hidden";
      setMessageModalOpen(true);
    };

    const closeMessageModal = () => {
      window.scrollTo(0, 0);
      document.body.style.overflow = "unset";
      setMessageModalOpen(false);
    };

    const handleShareButtonClick = async () => {
      if (navigator.canShare) {
        setShareLoading(true);
        const data = document.getElementById("post-card-container");
        if (message?.message?.length >= 100) {
          document.getElementById("message-text").innerText = message.message;
        }
        const canvas = await html2canvas(data, {
          useCORS: true,
          ignoreElements: (element) => {
            const ignoreIds = [
              "selfie-fullscreen-button",
              "selfie-close-button",
              "message-modal-open-button",
              "share-button",
            ];
            return ignoreIds.includes(element.id);
          },
        });
        if (message?.message?.length >= 100) {
          document.getElementById("message-text").innerText =
            message.message.substring(0, 100) + "...";
        }
        canvas.toBlob(
          function (blob) {
            const filesArray = [
              new File([blob], `${message.from} sent you a Gesture.png`, {
                type: blob.type,
              }),
            ];
            setShareLoading(false);
            if (navigator.canShare({ files: filesArray })) {
              navigator
                .share({ files: filesArray })
                .then(() => console.log("Share was successful."))
                .catch((error) => {
                  if (error.name !== "AbortError") {
                    toast.error(
                      "If you do not see your device's share menu take a screenshot to share instead.",
                      errorToastOptions
                    );
                  }
                });
            } else {
              toast.error(
                "If you do not see your device's share menu take a screenshot to share instead.",
                errorToastOptions
              );
            }
          },
          "image/png",
          1.0
        );
      } else {
        toast.error("Your device does not support sharing", errorToastOptions);
      }
    };

    return (
      <div className="post-card-main-container" ref={ref}>
        {shareLoading && (
          <div className="share-loading">
            <ClipLoader size={100} color="#2B08A0" />
          </div>
        )}
        <AnimatePresence initial={false} exitBeforeEnter={true}>
          {messageModalOpen && (
            <MessageModal message={message} handleClose={closeMessageModal} />
          )}
        </AnimatePresence>
        <ToastContainer />
        <div className="post-card-container" id="post-card-container">
          <div className="post-card-logo">
            <img
              className="post-card-logo-img"
              src={fallbackAssets ? postCardLogo : message.designElements?.[1]}
              alt="post card logo"
            />
          </div>

          <div className="post-card-names">
            <div
              className="post-card-names-img"
              style={{
                backgroundImage: `url(${
                  fallbackAssets ? postCardNames : message.designElements?.[2]
                })`,
              }}
            >
              {message.to ? (
                <p className="message-to">Hi {message.to},</p>
              ) : (
                <p className="message-to">Hi,</p>
              )}
              <p className="message-from">{message.from} sent you a Gesture</p>
            </div>
          </div>

          <div className="post-card-selfie">
            <img
              className="post-card-selfie-img"
              src={
                fallbackAssets ? postCardSelfie : message.designElements?.[3]
              }
              alt="post card selfie"
            />
            <motion.div
              className="message-selfie-container"
              variants={selfieVariant}
              initial="standard"
              animate={selfieFull ? "full" : "standard"}
            >
              <img
                className={
                  selfieFull ? "message-selfie-full" : "message-selfie"
                }
                id="message-selfie"
                src={message.selfie}
                alt="selfie"
              />
            </motion.div>
            <OpenInFullIcon
              className={
                selfieFull
                  ? "selfie-fullscreen-button disabled"
                  : "selfie-fullscreen-button"
              }
              id="selfie-fullscreen-button"
              fontSize="large"
              onClick={handleSelfieFull}
            />
            {selfieFull && (
              <CloseIcon
                className="selfie-close-button"
                id="selfie-close-button"
                fontSize="large"
                onClick={handleCloseSelfieFull}
              />
            )}
          </div>

          {message.message && (
            <div className="post-card-message">
              {message?.message?.length < 100 ? (
                <div
                  className="post-card-message-img"
                  style={{
                    backgroundImage: `url(${
                      fallbackAssets
                        ? postCardMessage
                        : message.designElements?.[4]
                    })`,
                  }}
                >
                  <p id="message-text" className="message-text">
                    {message.message}
                  </p>
                </div>
              ) : (
                <div
                  className="post-card-message-img"
                  style={{
                    backgroundImage: `url(${
                      fallbackAssets
                        ? postCardMessage
                        : message.designElements?.[4]
                    })`,
                  }}
                >
                  <p id="message-text" className="message-text">
                    {message.message.substring(0, 100) + "..."}
                  </p>
                  <button
                    id="message-modal-open-button"
                    className="message-modal-open-button"
                    onClick={() =>
                      messageModalOpen
                        ? closeMessageModal()
                        : openMessageModal()
                    }
                    disabled={selfieFull}
                  >
                    See Full Message
                  </button>
                </div>
              )}
            </div>
          )}

          <div className="post-card-doodle">
            <img
              className="post-card-doodle-img"
              src={
                fallbackAssets ? postCardDoodle : message.designElements?.[5]
              }
              alt="post card doodle"
            />
            <img className="message-doodle" src={message.doodle} alt="doodle" />
            <div className="message-doodle-background"></div>
            {!preview && (
              <button
                className={
                  selfieFull ? "share-button disabled" : "share-button"
                }
                id="share-button"
                onClick={handleShareButtonClick}
              >
                Share
              </button>
            )}
          </div>
        </div>

        {!preview && <Footer clickDisabled={selfieFull} />}
      </div>
    );
  }
);

export default PostCard;
